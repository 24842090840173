<template>
  <b-modal
    id="import-modal"
    :size="sizeModal"
    :visible="showImportModal"
    shadow
    backdrop
    centered
    no-close-on-backdrop
    hide-footer
    @change="(val) => $emit('update:show-import-modal', val)"
  >
    <!-- SECTION: Header -->
    <template #modal-header="{ hide }">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <h3 class="mb-0">
          {{ $t('importHeader') }}
        </h3>
        <b-button
          variant="flat-secondary"
          class="px-1 py-50"
          @click="hide"
        >
          <feather-icon
            icon="XIcon"
            size="24"
          />
        </b-button>
      </div>
    </template>
    <!-- !SECTION -->

    <!-- SECTION: BODY -->
    <form-wizard
      ref="refFormWizard"
      hide-buttons
      class="import-form-wizard steps-transparent"
    >
      <template #title>
        <div class="my-50">
          <span>{{ $t('importBodyText_1') }} {{ $t('customers') }} {{ $t('importBodyText_2') }}</span>
        </div>
      </template>

      <tab-content
        :title="$t('formTitle_1')"
        icon="feather icon-upload-cloud"
      >
        <customers-import-upload-file
          :is-initial="isInitial"
          :is-file-loading="isFileLoading"
          @files-change="filesChange"
          @next-step="formWizardNextStep"
          @hide-modal="hideModal"
          @download-sample-file-handle="downloadSampleFileHandle"
        />
      </tab-content>
      <tab-content
        :title="$t('formTitle_2')"
        icon="feather icon-check"
      >
        <customers-import-validate
          :data-import="dataImport"
          :is-file-success="isFileSuccess"
          :is-importing="isImporting"
          :file-name="fileName"
          @reset="reset"
          @next-step="formWizardNextStep"
          @prev-step="formWizardPrevStep"
          @hide-modal="hideModal"
          @import-file="importFile"
        />
      </tab-content>
      <tab-content
        :title="$t('formTitle_3')"
        icon="feather icon-user-check"
      >
        <customers-import-done
          :is-file-failed="isFileFailed"
          :is-import-success="isImportSuccess"
          :is-import-failed="isImportFailed"
          :import-response="importResponse"
          :import-error="importError"
          :file-name="fileName"
          @reset="reset"
          @next-step="formWizardNextStep"
          @prev-step="formWizardPrevStep"
          @hide-modal="hideModal"
        />
      </tab-content>
    </form-wizard>
    <!-- !SECTION -->
  </b-modal>
</template>

<script>
import {
  BModal, BButton,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
import { FormWizard, TabContent } from 'vue-form-wizard'

import { getUserData } from '@/api/auth/utils'
import { resolveGender } from '@/constants/selectOptions'

import { useInputCsvToArray, useInputExcelToArray } from '@core/comp-functions/forms/form-utils'

import useCustomerHandle from '@customer/useCustomerHandle.js'

import CustomersImportUploadFile from './CustomersImportUploadFile.vue'
import CustomersImportValidate from './CustomersImportValidate.vue'
import CustomersImportDone from './CustomersImportDone.vue'

export default {
  components: {
    BModal,
    BButton,

    FormWizard,
    TabContent,

    CustomersImportUploadFile,
    CustomersImportValidate,
    CustomersImportDone,
  },
  props: {
    showImportModal: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    // ANCHOR Form wizard
    const refFormWizard = ref()
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }
    const formWizardPrevStep = () => {
      refFormWizard.value.prevTab()
    }

    const formWizardBackToFirstStep = () => {
      refFormWizard.value.navigateToTab(0)
    }

    const formWizardGoToLastStep = () => {
      refFormWizard.value.changeTab(refFormWizard.value.activeTabIndex, refFormWizard.value.tabs.length - 1)
      // refFormWizard.value.changeTab(0, 2)
    }

    // ANCHOR handle import
    const STATUS_INITIAL = 0
    const STATUS_FILE_LOADING = 1
    const STATUS_FILE_SUCCESS = 2
    const STATUS_FILE_FAILED = 3
    const STATUS_IMPORTING = 4
    const STATUS_IMPORT_SUCCESS = 5
    const STATUS_IMPORT_FAILED = 6

    const currentStatus = ref(STATUS_INITIAL)
    const uploadFile = ref(null)
    const importResponse = ref(null)
    const importError = ref(null)
    const fileName = ref(null)
    const fileNameExtension = ref(null)
    const dataImport = ref([])

    const isInitial = computed(() => currentStatus.value === STATUS_INITIAL)
    const isFileLoading = computed(() => currentStatus.value === STATUS_FILE_LOADING)
    const isFileSuccess = computed(() => currentStatus.value === STATUS_FILE_SUCCESS)
    const isFileFailed = computed(() => currentStatus.value === STATUS_FILE_FAILED)
    const isImporting = computed(() => currentStatus.value === STATUS_IMPORTING)
    const isImportSuccess = computed(() => currentStatus.value === STATUS_IMPORT_SUCCESS)
    const isImportFailed = computed(() => currentStatus.value === STATUS_IMPORT_FAILED)

    const { importCustomers, exportCustomersTemplateCsv } = useCustomerHandle()

    function reset() {
      currentStatus.value = STATUS_INITIAL
      importError.value = null
      importResponse.value = null
      uploadFile.value = null
      fileName.value = null
      fileNameExtension.value = null
      dataImport.value = []

      this.formWizardBackToFirstStep()
    }

    async function filesChange(fileList) {
      currentStatus.value = STATUS_FILE_LOADING
      uploadFile.value = fileList[0]
      fileName.value = uploadFile.value.name
      fileNameExtension.value = uploadFile.value.name.split('.').at(-1)
      if (fileNameExtension.value === 'csv') {
        useInputCsvToArray(uploadFile.value, response => {
          if (response.error) {
            importError.value = response.error
            formWizardGoToLastStep()
            return
          }
          dataImport.value = response

          if (dataImport.value.length) {
            currentStatus.value = STATUS_FILE_SUCCESS
          } else {
            currentStatus.value = STATUS_FILE_FAILED
            importError.value = 'Import file is empty.'
            formWizardGoToLastStep()
            return
          }
          this.formWizardNextStep()
        })
      } else if (fileNameExtension.value === 'xlsx') {
        try {
          // TODO: use useExcelToArray() instead of useInputExcelToArray()
          dataImport.value = await useInputExcelToArray(uploadFile.value)
          if (dataImport.value.length) {
            currentStatus.value = STATUS_FILE_SUCCESS
          } else {
            currentStatus.value = STATUS_FILE_FAILED
            importError.value = 'Import file is empty.'
            formWizardGoToLastStep()
            return
          }
          this.formWizardNextStep()
        } catch (err) {
          console.error({ error: err })
          currentStatus.value = STATUS_FILE_FAILED
          importError.value = err.message
          formWizardGoToLastStep()
        }
      }
    }

    function importFile(dataValidated) {
      this.formWizardNextStep()
      currentStatus.value = STATUS_IMPORTING
      const dataToRequest = dataValidated.map(item => {
        let data = {}
        try {
          data = {
            creator: getUserData().employeeData.id,
            firstName: item['Customer First Name']?.toString(),
            lastName: item['Customer Last Name']?.toString(),
            phoneNumber: item['Phone Number']?.toString(),
            emailAddress: item['Email Address']?.toString(),
            description: item.Description?.toString(),
            birthDay: item.Birthday?.toString(),
            gender: resolveGender(item.Gender)?.toString(),
            // addressCountry: item.Country,
            // addressCity: item.City,
            // addressDistrict: item.District,
            // addressWard: item.Ward,
            addressStreet: item.Street?.toString(),
          }
        } catch (error) {
          console.error({ error })
          importError.value = 'Import file is wrong format. Please download sample file.'
        }
        return data
      })
      if (importError.value) {
        currentStatus.value = STATUS_IMPORT_FAILED
        return
      }

      importCustomers(dataToRequest)
        .then(res => {
          importResponse.value = res
          currentStatus.value = STATUS_IMPORT_SUCCESS
          emit('fetch-data')
        })
        .catch(err => {
          console.error({ err })
          importError.value = err.message
          currentStatus.value = STATUS_IMPORT_FAILED
        })
    }

    // modal download sample file
    function downloadSampleFileHandle() {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.downloadSampleFile') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            exportCustomersTemplateCsv()
          }
        })
    }

    // ANCHOR handle Modal
    const sizeModal = computed(() => (currentStatus.value === STATUS_FILE_SUCCESS ? 'xl' : 'lg'))

    function hideModal() {
      this.$bvModal.hide('import-modal')
    }
    return {
      hideModal,
      sizeModal,

      isInitial,
      isFileLoading,
      isFileSuccess,
      isFileFailed,
      isImporting,
      isImportSuccess,
      isImportFailed,
      currentStatus,
      importResponse,
      importError,
      uploadFile,
      fileName,
      dataImport,

      filesChange,
      reset,
      importFile,
      importCustomers,
      downloadSampleFileHandle,

      // form wizard
      refFormWizard,
      formWizardNextStep,
      formWizardPrevStep,
      formWizardBackToFirstStep,
      formWizardGoToLastStep,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.vue-form-wizard ::v-deep {
  .wizard-nav-pills {
    justify-content: center;
    width: 100%;
    li {
      padding-bottom: 10px !important;
      pointer-events: none !important; // prevent click to content tab navigation
    }
  }
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
