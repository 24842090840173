<template>
  <b-card body-class="p-0">
    <b-alert
      v-if="loadingFetchAncillary"
      show
      variant="warning"
      class="mt-1 px-2 py-1 d-flex-center gap-2 justify-content-start"
    >
      <b-spinner
        variant="warning"
        tag="span"
      />
      <span>
        {{ $t('loading') }}
      </span>
    </b-alert>
    <div v-else-if="isEmpty(serviceOptions)">
      <b-alert
        show
        variant="warning"
        class="mt-1"
      >
        <div class="alert-body text-center text-warning">
          <feather-icon
            class="mr-25"
            icon="FrownIcon"
            size="25"
          />
          <span>
            {{ $t('flight.noAncillary') }}
          </span>
        </div>
      </b-alert>
    </div>

    <b-card
      v-else
      class="mb-0 border-0"
      body-class="p-0"
    >
      <!-- NOTE: VN1A bỏ qua các hành trình có điểm dừng  https://discord.com/channels/1054696448110903327/1315896234463985694/1318860064261537793 -->
      <div v-if="!isDevelopment && selectedTrip.some(trip => ['VN1A'].includes(trip?.source) && trip?.segments?.length > 1)">
        <b-alert
          show
          variant="warning"
        >
          <div class="alert-body text-center text-warning">
            <feather-icon
              class="mr-25"
              icon="AlertCircleIcon"
              size="25"
            />
            <span class="fw-700">
              Nếu có nhu cầu mua dịch vụ bổ sung cho chuyến bay có điểm dừng, vui lòng liên hệ booker hỗ trợ!
            </span>
          </div>
        </b-alert>
      </div>

      <b-row>
        <!-- ANCHOR - Chặng bay -->
        <b-col md="5">
          <b-form-group
            :label="$t('flight.flightSegment')"
            label-for="chang-bay"
            class="pb-25 mb-0"
          >
            <v-select
              v-model="segment"
              input-id="chang-bay"
              label="segmentData"
              class="select-size-sm mr-1 w-100"
              :clearable="false"
              :options="segmentOptions"
              :placeholder="$t('flight.placeholderSelect')"
            >
              <template #option="{ segmentData }">
                <div class="fw-700">
                  <span>
                    {{ $t('flight.priceReport.airline') }}:
                    {{ segmentData.source ? segmentData.source : segmentData.airline }} |
                  </span>
                  <span>
                    {{ segmentData ? $t('flight.segment'): $t('flight.itinerary') }}:
                    {{ ['TR'].includes(segmentData?.source) ? segmentData.itemFlight : `${segmentData.departure.IATACode}-${ segmentData.arrival.IATACode}` }}
                    ({{ convertISODateTime(segmentData.departure.at).dayAndMonth }})
                  </span>
                </div>
              </template>

              <template #selected-option="{ segmentData }">
                <div class="font-small-4 fw-700">
                  <span>
                    {{ segmentData.source ? segmentData.source : segmentData.airline }}
                  </span>
                  |
                  <span>
                    {{ ['TR'].includes(segmentData?.source) ? segmentData.itemFlight : `${segmentData.departure.IATACode} - ${segmentData.arrival.IATACode}` }}
                    ({{ convertISODateTime(segmentData.departure.at).dayAndMonth }})
                  </span>
                </div>
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="my-50">

      <!-- ANCHOR - Passenger -->
      <div v-if="passengersData">
        <b-row
          v-for="(passenger, index) in passengersData"
          :key="index"
          class="w-100 m-0"
        >
          <b-col
            class="px-0 mt-1 mt-md-0 pb-25 pb-md-1 pt-25"
            md="4"
          >
            {{ $t('flight.passenger') }}:
            <span class="font-weight-bolder">
              {{
                passenger.firstName && passenger.lastName
                  ? `${passenger.title} ${passenger.lastName} ${passenger.firstName}`
                  : `${$t(`reservation.${passenger.paxType}`)} #${index + 1}`
              }}
            </span>
          </b-col>

          <!-- ANCHOR - Gói dịch vụ -->
          <b-col
            cols="12"
            md="5"
            class="px-0"
          >
            <v-select
              :id="`service-packages-${index}`"
              v-model="passenger.servicePackage"
              class="ssrs_package_select select-size-sm w-100"
              label="code"
              :filter-by="filterBy"
              :placeholder="$t('flight.servicePlaceholder')"
              :disabled="disableServicePackage"
              :options="serviceListOptions"
              @input="(val) => setDefaultPassengerAmount(val, passenger)"
            >
              <template #option="data">
                <div :class="`d-flex justify-content-between ${isMobileView ? 'font-small-1' : 'font-small-4'}`">
                  <span :class="`mr-75 $${resolveVariantByBagg(data)}`">
                    {{ resolveSsrNameByCode(['TR', 'JQ'].includes(data?.airline) ? `${data.airline}_${data.code}` : data.code) }}
                  </span>
                  <span class="font-weight-bolder text-warning">
                    {{
                      formatCurrency(resolvePriceService(data, passenger))
                    }}
                  </span>
                </div>
              </template>
              <template #selected-option="data">
                <div :class="`font-weight-bolder ${isMobileView ? 'font-small-1' : 'font-small-4'}`">
                  <span class="mr-50">
                    {{ resolveSsrNameByCode(['TR'].includes(data?.airline) ? `${data.airline}_${data.code}` : data.code) }}
                  </span>
                  (<span class="text-warning"> {{ formatCurrency(resolvePriceService(data, passenger)) }} </span>)
                </div>
              </template>
            </v-select>
          </b-col>

          <!-- ANCHOR - Số lượng mua -->
          <b-col
            cols="4"
            md="2"
            class="mt-50 mt-md-0 px-0 px-md-1"
          >
            <v-select
              :id="`service-packages-amount-${index}`"
              v-model="passenger.serviceAmount"
              class="select-size-sm w-100"
              label="text"
              :placeholder="$t('flight.Amount')"
              :clearable="false"
              :disabled="disableServicePackage || (segment && ['VN1A'].includes(segment.source)) || !passenger.servicePackage"
              :options="amountOptions"
            >
              <template #option="{ text }">
                <span class="font-small-3">
                  {{ text }} {{ $t('flight.package') }}
                </span>
              </template>
              <template #selected-option="{ text }">
                <span class="font-small-3 pl-50">
                  {{ text }} {{ $t('flight.package') }}
                </span>
              </template>
            </v-select>
          </b-col>
        </b-row>
      </div>

      <div
        v-if="!isHideFunction"
        class="text-center mt-50"
      >
        <b-button
          class="mr-2"
          variant="outline-warning"
          @click="handleClearForm"
        >
          {{ $t('flight.clear') }}
        </b-button>

        <b-button
          variant="primary"
          @click="handleClickAddButton"
        >
          {{ $t('flight.addService') }}
        </b-button>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import {
  BSpinner, BRow, BCol, BCard, BAlert, BButton, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, computed,
  toRefs,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuidv4 } from 'uuid'

import { resolveSsrBagsName, resolveSsrNameByCode, resolveVariantByBagg } from '@/constants/selectOptions'
import store from '@/store'
import env from '@/libs/env'

import { formatCurrency, convertISODateTime } from '@core/utils/filter'

import useCreateBookingHandle from '@flightv2/reservation/useCreateBookingHandle'
import useBookingHandle from '@flightv2/useBookingHandle'

import useToast from '@useToast'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAlert,
    BFormGroup,
    BSpinner,

    vSelect,
  },
  props: {
    ssrOthers: {
      type: Array,
      default: () => [],
    },
    passengerDataToAdd: {
      type: Array,
      default: () => [],
    },
    selectedTrip: {
      type: Array,
      default: () => [],
    },
    isCombination: {
      type: Boolean,
      default: false,
    },
  },
  /*
  ----- CÁC HÃNG MUA DỊCH VỤ THÊM KHI TẠO BOOKING -----
  - MUA THEO CHẶNG:  QH, VN1A, VU, AK, F1, TH.
  - MUA THEO HÀNH TRÌNH:  VJ.
  - MUA THEO CẢ CHẶNG HOẶC CHẶNG/HÀNH TRÌNH: TR, JQ.
  */
  setup(props) {
    const { toastSuccess, toastWarning, toastError } = useToast()

    const {
      FLIGHT_APP_STORE_MODULE_NAME,
      getAddonsData,
      loadingFetchAncillary,
    } = useBookingHandle()
    const {
      isHideFunction,
    } = useCreateBookingHandle()
    const { ssrOthers, isCombination } = toRefs(props)
    const passengersData = ref(null)

    function setBlankDataPassengers() {
      passengersData.value = cloneDeep(
        props.passengerDataToAdd.filter(
          passenger => passenger.paxType !== 'INFANT',
        ),
      )
    }

    // Set passenger data
    watch(() => props.passengerDataToAdd, () => {
      setBlankDataPassengers()
    }, { deep: true, immediate: true })

    // SECTION  SERVICE ADDONS
    const serviceOptions = computed(() => {
      if (isEmpty(ssrOthers.value)) return []
      return ssrOthers.value.map(item => ({
        ...item,
        ssrName: 'ssrOthers',
        ssrId: uuidv4(),
      }))
    })

    function getSsrDataBySource(ssrOtherLists, tripOrSegment, segmentFlights) {
      if (['VN1A'].includes(tripOrSegment.source)) {
        const result = ssrOtherLists.filter(item => item.segmentIds.map(item => item.split('-')[0]).includes(tripOrSegment.departure.IATACode) && (isCombination.value ? true : (item?.itineraryId === tripOrSegment.airlineItineraryId)))
        return result
      }

      if (['TH'].includes(tripOrSegment.source)) {
        return ssrOtherLists.filter(item => item.flights === segmentFlights && item.segmentIds.includes(tripOrSegment.segmentId ? tripOrSegment.segmentId : tripOrSegment.segments[0].segmentId))
      }

      if (['TR'].includes(tripOrSegment.source)) {
        const result = ssrOtherLists.filter(bagItem => bagItem.flights === tripOrSegment.itemFlight)
        return result
      }

      if (['VJ'].includes(tripOrSegment.source)) {
        const result = ssrOtherLists.filter(item => segmentFlights.includes(item.flights) && item.segmentIds[0] === tripOrSegment.segments[0].segmentId)
        return result
      }

      if (['VU'].includes(tripOrSegment.source)) {
        const result = ssrOtherLists.filter(item => (item.flights === segmentFlights) && item.segmentIds.includes(tripOrSegment.segmentId ? tripOrSegment.segmentId : tripOrSegment.segments[0].segmentId))
        return result
      }

      return ssrOtherLists.filter(item => item.flights === segmentFlights)
    }

    const uniqueByFlights = array => {
      const map = new Map()
      return array.filter(item => {
        const key = JSON.stringify(item.flights)
        if (!map.has(key)) {
          map.set(key, true)
          return true
        }
        return false
      })
    }

    // SECTION SEGMENT
    const segment = ref(null)
    const segmentOptions = ref([])

    watch(() => serviceOptions.value, ssrOtherOptionValues => {
      segment.value = null
      segmentOptions.value = []
      setBlankDataPassengers()

      if (!isEmpty(ssrOtherOptionValues)) {
        const flightOrSegmentLists = []
        let airlineSegmentId = 1

        // NOTE: VN1A bỏ qua các hành trình có điểm dừng  https://discord.com/channels/1054696448110903327/1315896234463985694/1318860064261537793
        const trips = !env.isDevelopment && props.selectedTrip.some(trip => ['VN1A'].includes(trip?.source)) ? props.selectedTrip.filter(trip => trip?.segments?.length < 2) : props.selectedTrip

        trips.forEach((trip, tripIndex) => {
          if (['TR', 'JQ'].includes(trip.source)) { // TR, JQ thêm hành trình vào list, result cả chặng và hành trình
            const listSsrInTrip = ssrOtherOptionValues.filter(item => item.segmentIds.every(itemSegmentId => trip.segments.some(segment => segment.segmentId === itemSegmentId)))
            const listSsrUnique = uniqueByFlights(listSsrInTrip)
            listSsrUnique.forEach(item => {
              flightOrSegmentLists.push({ ...trip, itemFlight: item.flights, segments: trip.segments.filter(segment => item.segmentIds.includes(segment.segmentId)) })
            })
          } else {
            // eslint-disable-next-line no-lonely-if
            if (['VN1A', 'AK', 'TH'].includes(trip.source)) {
              trip.segments.forEach(item => {
                flightOrSegmentLists.push({
                  ...item,
                  tripFlight: `${trip.departure.IATACode}-${trip.arrival.IATACode}`,
                  ...(isCombination.value && {
                    tripId: tripIndex + 1,
                    airlineSegmentId: String(airlineSegmentId),
                  }),
                })

                if (isCombination.value) {
                  airlineSegmentId += 1
                }
              })
            } else {
              flightOrSegmentLists.push(trip) // VJ
            }
          }
        })

        flightOrSegmentLists.forEach((dataFlight, index) => {
          const flightsName = ['TR', 'JQ'].includes(dataFlight?.source ?? '')
            ? dataFlight.segments.map(s => `${s.departure.IATACode}-${s.arrival.IATACode}`).join(' | ')
            : `${dataFlight.departure.IATACode}-${dataFlight.arrival.IATACode}`

          const mappedAddonsData = {
            segmentData: {
              ...dataFlight,
              segmentIndex: index + 1,
              flights: flightsName,
            },
            ssrData: getSsrDataBySource(ssrOtherOptionValues, dataFlight, flightsName),
          }

          if (mappedAddonsData.ssrData.length > 0) {
            segmentOptions.value.push(mappedAddonsData)
          }
        })
      }
    }, { deep: true, immediate: true })

    // SECTION ADDONS OPTION
    const serviceListOptions = ref([])
    const amountOptions = ref([])

    watch(() => segment.value, segmentValue => {
      amountOptions.value = []
      serviceListOptions.value = []
      setBlankDataPassengers()

      if (segmentValue) {
        const data = segmentValue.ssrData.map(item => ({
          segmentData: segmentValue.segmentData,
          ...item,
        }))

        if (!isEmpty(data)) {
          serviceListOptions.value = data.filter(item => !isEmpty(item.fares) && (item.fares[0].total >= 0) /* Hiển thị cả SSR 0đ cho AK */)
          serviceListOptions.value = serviceListOptions.value.filter(item => !['UMNR', 'INFT'].includes(item.code))
        }

        // NOTE: mua nhiều gói chỉ áp dụng baggage VN1A, QH
        // if (data[0]?.segmentData?.source?.includes('VN1A') && !isEmpty(data) && data[0].ssrName === 'ssrBags') {
        //   // const option = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        //   const option = [1] // FIXME : VNA 1 gói / hành trình
        //   amountOptions.value.push(...option)
        // } else {
        amountOptions.value.push(1)
        // }
      }
    }, { deep: true })

    function resolvePriceService(service, passenger) {
      const fare = service.fares.find(fare => fare.paxType === passenger.paxType) || service.fares[0]
      return fare.total || 0
    }

    // handle set setDefaultPassengerAmount
    const setDefaultPassengerAmount = (val, passenger) => {
      passenger.serviceAmount = val !== null ? 1 : null
    }

    // handle clear form
    function handleClearForm() {
      segment.value = null
      // serviceSelected.value = null
      setBlankDataPassengers()
    }

    // ANCHOR - HANDLE ADD-ONS --- Add service
    function addDataToStore(ssrsToAdd) {
      for (const ssrData of ssrsToAdd) {
        const rawData = cloneDeep(getAddonsData.value)
        rawData.push(ssrData)

        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setAddonsSelectData`, rawData)
        handleClearForm()
      }
      toastSuccess({ title: 'Thêm dịch vụ thành công!' })
    }

    // SECTION DISABLE FORM
    const disableServicePackage = computed(() => !segment.value)

    function handleClickAddButton() {
      const checkArrayItems = passengersData.value.every(item => item.servicePackage === null && item.serviceAmount === null)

      if (!segment.value || checkArrayItems) {
        toastWarning({ title: 'Vui lòng chọn gói dịch vụ!' })
        return
      }

      // NOTE DATA SSR
      const dataSSR = passengersData.value.filter(p => p.servicePackage).map(passenger => {
        const { servicePackage } = passenger

        const data = {
          // ========== Data Create Booking ==========
          code: servicePackage.code,
          type: '',
          name: '',
          ...(servicePackage.details && {
            details: servicePackage.details.map(details => ({
              weight: String(details.weight),
              unit: details.unit,
            })),
          }),
          ...(['VJ', 'VZ', 'VN'].includes(servicePackage.airline) && { itineraryId: servicePackage.segmentData.airlineItineraryId }),
          ...(['TR', 'JQ'].includes(servicePackage.source) && {
            itineraryId: servicePackage.itineraryId ?? '',
            segmentIds: servicePackage.segmentIds ?? [],
          }),
          ...(['TH'].includes(servicePackage.airline) && { itineraryId: servicePackage.segmentData.itineraryId }),
          ...(['VU'].includes(servicePackage.airline) && { itineraryId: servicePackage.segmentData.segments[0].segmentValue }),
          segmentId: ['VN'].includes(servicePackage.airline)
            ? String(servicePackage?.segmentData?.airlineSegmentId)
            : ['TR', 'JQ'].includes(servicePackage.source ?? servicePackage.airline)
              ? servicePackage?.segmentIds?.join(' | ')
              : isEmpty(servicePackage.segmentIds)
                ? ''
                : servicePackage.segmentIds[0], // NOTE segmentIds từ api list-ancillary
          paxId: passenger.paxId,
          addonValue: ['VJ', 'VZ', 'VN', 'TR', 'JQ'].includes(servicePackage.airline) ? servicePackage.ssrValue : resolvePriceService(servicePackage, passenger),

          // ========== Others ==========
          paxType: passenger.paxType,
          ssrName: servicePackage.ssrName, // toCheck
          ssrId: servicePackage.ssrId, // toCheck

          // ========== Data Show Price ==========
          // HÀNH KHÁCH
          firstName: passenger.firstName,
          lastName: passenger.lastName,

          // CHẶNG BAY
          flights: (servicePackage.airline === 'QH' && servicePackage.ssrName === 'ssrBags')
            ? servicePackage.segmentData.flights
            : servicePackage.flights,

          tripId: servicePackage.segmentData?.tripId ?? servicePackage.segmentData?.segments?.[0]?.tripId ?? '', // check to add segmentId follow segmentValue[0]

          // HÃNG HÀNG KHÔNG
          airline: servicePackage.airline,

          // GIÁ
          addonPrice: resolvePriceService(servicePackage, passenger),
          amount: passenger.serviceAmount,

          // DỊCH VỤ
          serviceName: servicePackage.details
            ? resolveSsrBagsName(servicePackage)
            : resolveSsrNameByCode(['TR', 'JQ'].includes(servicePackage.airline) ? `${servicePackage.airline}_${servicePackage.code}` : servicePackage.code),
        }

        return data
      })

      const existingPaxSsrs = dataSSR.filter(paxSsr => {
        let existingPax
        let errTitle
        if (['TR', 'JQ'].includes(paxSsr.source ?? paxSsr.airline)) {
          existingPax = getAddonsData.value.find(ssr => ssr.paxId === paxSsr.paxId && ssr.ssrId === paxSsr.ssrId)

          if (existingPax) {
            errTitle = `Hành khách ${paxSsr.firstName ? `${paxSsr.lastName} ${paxSsr.firstName} ` : `${paxSsr.paxType} ${paxSsr.paxId}`} đã có gói dịch vụ ở chặng này, không thể mua thêm!`
          }
        } else {
          existingPax = getAddonsData.value.find(ssr => ssr.paxId === paxSsr.paxId && ssr.ssrId === paxSsr.ssrId)
        }

        if (existingPax) {
          const data = existingPax
          if (data.airline === 'AK') {
            data.code = data.serviceName
          }

          const addOnlyOneSsrText = `${data.firstName ? `${data.lastName} ${data.firstName} ` : `${data.paxType} ${data.paxId} `} chỉ được mua một gói ${resolveSsrNameByCode(data.code) || data.code} trên một chặng/hành trình.`
          toastError({
            title: ['TR', 'JQ'].includes(paxSsr.source ?? paxSsr.airline) ? errTitle : addOnlyOneSsrText,
          })
          return data
        }

        return existingPax
      })

      if (existingPaxSsrs.length === 0) {
        addDataToStore(dataSSR)
      }
    }

    function filterBy(option, label, search) {
      const code = (option?.code ?? '').toUpperCase()
      const searchUpper = (search ?? '').toUpperCase()
      const detailsUpper = (`${option?.details[0]?.weight}` ?? '').toUpperCase()
      return code?.includes(searchUpper) || detailsUpper.includes(searchUpper)
    }

    return {
      handleClickAddButton,
      handleClearForm,
      setDefaultPassengerAmount,
      resolveSsrNameByCode,
      resolveSsrBagsName,
      resolvePriceService,

      serviceOptions,
      segmentOptions,
      serviceListOptions,
      amountOptions,
      passengersData,
      segment,

      disableServicePackage,
      formatCurrency,

      isEmpty,
      resolveVariantByBagg,
      convertISODateTime,
      filterBy,
      loadingFetchAncillary,
      isHideFunction,
      isDevelopment: env.isDevelopment,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.ssrs_package_select {
  ::v-deep .vs__dropdown-option {
    text-wrap: wrap !important;
  }

  ::v-deep .vs__dropdown-option--selected {
    color: black !important;

    &.vs__dropdown-option--highlight {
      color: black !important;
    }
  }
}
</style>
