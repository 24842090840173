<template>
  <div>
    <!-- SECTION Card ket qua -->
    <BCard header-class="py-1">
      <template #header>
        <div class="font-weight-bolder text-uppercase">
          {{ $t('packageConfig.packageConfigsList') }}
        </div>
      </template>

      <div class="d-flex-between mb-50 gap-3">
        <div
          class="flex-grow-1"
          style="max-width: 480px"
        >
          <b-input-group size="md">
            <template #prepend>
              <div class="d-flex align-items-center border rounded-left px-1 bg-light">
                <feather-icon
                  icon="SearchIcon"
                  size="20"
                />
              </div>
            </template>

            <b-form-input
              v-model="searchText"
              type="search"
              :placeholder="$t('packageConfig.placeholder.searchText')"
              :value="searchText"
              :reduce="val => val.value"
              maxlength="255"
              debounce="500"
              trim
              @input="(val) => $emit('update:searchText', val)"
            />
          </b-input-group>
        </div>
        <!-- BUTTON -->
        <div class="d-none d-md-flex  gap-2">
          <BButton
            v-if="canModify"
            variant="primary"
            @click="createButtonHandle"
          >
            {{ $t('packageConfig.packageConfigsCreate') }}
          </BButton>

          <BButton
            v-if="canGift"
            variant="info"
            class="d-flex-center gap-1"
            @click="giftButtonHandle"
          >
            <feather-icon
              icon="GiftIcon"
              size="16"
            />
            {{ $t('packageConfig.agencyPackageConfigsGift') }}
          </BButton>
        </div>
        <div class="d-block d-md-none">
          <b-dropdown
            variant="info"
            boundary="window"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MenuIcon"
                size="16"
              />
              <span class="pl-50">{{ $t('topup.moreDropdownTitle') }}</span>
            </template>
            <b-dropdown-item
              v-if="canModify"
              @click="createButtonHandle"
            >
              {{ $t('packageConfig.packageConfigsCreate') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canGift"
              @click="giftButtonHandle"
            >
              {{ $t('packageConfig.agencyPackageConfigsGift') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <BTable
        style="max-height: calc(100vh - 400px); height: calc(100vh - 400px)"
        :sticky-header="true"
        class="position-relative"
        :items="resultArray"
        responsive
        :striped="true"
        :fields="tableColumnsRole"
        primary-key="id"
        show-empty
        :empty-text="$t('noMatchingResult')"
        no-border-collapse
        :busy="loading"
        :hover="true"
        :borderless="true"
      >
        <template #table-busy>
          <div class="d-flex-center text-dark my-2 gap-2">
            <b-spinner class="align-middle" />
            <strong>{{ $t('loading') }}</strong>
          </div>
        </template>

        <template
          v-for="column in tableColumnsRole"
          #[`head(${column})`]=""
        >
          <span
            :key="column"
            class="text-dark text-nowrap"
          >
            {{ $t(`packageConfig.columns.${column}`) }}
          </span>
        </template>

        <template #cell(type)="{item}">
          <span>{{ item.type }}</span>
          <feather-icon
            v-if="item.isGift"
            icon="GiftIcon"
            class="text-info"
            size="16"
            style="margin-top: -3px; margin-left: 4px"
          />
        </template>

        <template #cell(price)="{item}">
          {{ formatCurrency(item.price) }}
        </template>

        <template #cell(isActive)="data">
          <IAmSwitch
            :checked.sync="data.item.enable"
            name="check-button"
            :confirm="true"
            custom-class="mr-0 mt-50 custom-control-success"
            :disabled="false"
            :re-update="false"
            @input="changeActive(data.item)"
          />
        </template>
        <template #cell(action)="row">
          <div class="d-flex gap-2">
            <BButton
              variant="flat-primary"
              size="sm"
              @click="row.toggleDetails"
            >
              Chi tiết
            </BButton>
            <BButton
              v-if="isRoleF2"
              variant="info"
              size="sm"
              @click="buyButtonHandle(row.item)"
            >
              {{ $t('packageConfig.agencyPackageConfigsBuy') }}
            </BButton>
            <BButton
              v-if="canModify"
              variant="info"
              size="sm"
              @click="editButtonHandle(row.item)"
            >
              {{ $t('packageConfig.agencyPackageConfigsEdit') }}
            </BButton>
            <BButton
              v-if="canModify"
              variant="danger"
              size="sm"
              @click="deletePackageConfig(row.item)"
            >
              {{ $t('delete') }}
            </BButton>
          </div>
        </template>
        <template #row-details="{ item }">
          <PackageDetail :options="item.descriptions" />
        </template>
      </BTable>

      <!-- ANCHOR: Table Footer -->
      <b-row class=" d-flex-center justify-content-md-between">
        <b-col
          cols="12"
          md="auto"
          class="d-flex align-items-center justify-content-center mb-50 mb-md-0"
        >
          <span class="font-weight-bolder">
            {{ $t('paginationText.showing') }}
          </span>
          <v-select
            v-model="sizePerPage"
            :options="sizePerPageLgOptions"
            :clearable="false"
            :append-to-body="true"
            :calculate-position="withPopper"
            class="per-page-selector d-inline-block mx-50"
          >
            <template #option="data">
              <span>
                {{ data.label }}
              </span>
            </template>

            <template #selected-option="data">
              <span>
                {{ data.label }}
              </span>
            </template>

            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
          <span class="font-weight-bolder">
            {{ $t('paginationText.from') }} {{ fromItem }} {{ $t('paginationText.to') }} {{ toItem }} {{
              $t('paginationText.outOf') }} {{ totalItems }}
          </span>
        </b-col>

        <b-col
          cols="12"
          md="auto"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="sizePerPage"
            first-number
            last-number
            class="mb-0 mt-25 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </BCard>

    <PackageConfigModal v-if="canModify" />
    <FormPackageConfigModal
      :item="packageConfig"
      @save="savePackageConfig"
    />
    <ModalBuy
      :buy-item-props="giftItem || buyItem"
      :is-gift="!!giftItem"
    />
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BInputGroup, BTable, BSpinner, BPagination, BFormInput,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { createPopper } from '@popperjs/core'
import { ref, computed, onUnmounted } from '@vue/composition-api'

import { sizePerPageLgOptions } from '@/constants/selectOptions'
import usePackageConfig from '@/views/retail-package/package-config/usePackageConfig'
import store from '@/store'
import retailPackageStore from '@/views/retail-package/retailPackageStore'

import { formatCurrency } from '@core/utils/filter'
import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import FormPackageConfigModal from './components/FormPackageConfigModal.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BTable,
    BSpinner,
    BPagination,
    BFormInput,
    FormPackageConfigModal,
    BDropdown,
    BDropdownItem,
    vSelect: () => import('vue-select'),
    IAmSwitch: () => import('@/components/IAmSwitch.vue'),
    PackageConfigModal: () => import('@/views/retail-package/package-config/PackageConfigModal.vue'),
    ModalBuy: () => import('@/views/retail-package/components/ModalBuy.vue'),
    PackageDetail: () => import('@/views/retail-package/components/PackageDetail.vue'),
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownMaxWidth = width || '200px'
      dropdownList.style.maxWidth = dropdownMaxWidth
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
  setup() {
    if (!store.hasModule('app-package-config')) {
      store.registerModule('app-package-config', retailPackageStore)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('app-package-config')) {
        store.unregisterModule('app-package-config')
      }
    })

    // ANCHOR: Permission
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const meData = computed(() => store.getters['userStore/getMeData'])

    const canModify = computed(() => {
      const myType = meData?.value?.type
      if (!myType) return false
      if (isRoleF1.value && ['ADM'].includes(myType)) return true
      return false
    })

    const canGift = computed(() => {
      const myType = meData?.value?.type
      if (!myType) return false
      if (isRoleF1.value && ['ADM', 'OPE'].includes(myType)) return true
      return false
    })

    const {
      searchText,
      resultArray,
      currentPage,
      sizePerPage,
      loading,
      totalItems,
      fromItem,
      toItem,
      fetchPackageConfigs,
      deletePackageConfig: deletePack,
      tableColumns,
      changeActive,
      savePackageConfig: save,
      getMinPricePackageConfig,
    } = usePackageConfig()

    const tableColumnsRole = isRoleF2.value ? tableColumns.value.filter(item => !['isActive'].includes(item)) : tableColumns.value

    fetchPackageConfigs()
    const buyItem = ref(null)
    const packageConfig = ref(undefined)
    function buyButtonHandle(packageItem) {
      buyItem.value = packageItem
      this.$bvModal.show('modal-buy-package-config')
    }

    function editButtonHandle(packageItem) {
      packageConfig.value = packageItem
      this.$bvModal.show('form-package-config-modal')
    }

    function createButtonHandle() {
      packageConfig.value = undefined
      this.$bvModal.show('form-package-config-modal')
    }

    const giftItem = ref(null)
    async function giftButtonHandle() {
      if (!giftItem.value) {
        giftItem.value = getMinPricePackageConfig.value
      }
      this.$bvModal.show('modal-buy-package-config')
    }

    async function savePackageConfig(payload) {
      await save(packageConfig.value?.id, payload)
      this.$bvModal.hide('form-package-config-modal')
      fetchPackageConfigs()
    }
    async function deletePackageConfig(item) {
      // ANCHOR modal confirm
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmDelete') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            await deletePack(item.id)
            fetchPackageConfigs()

            // Add your logic here
          }
        })
        .catch(err => {
          console.error(err)
          // Add your error handling here
        })
    }
    return {
      loading,
      packageConfig,
      deletePackageConfig,
      resultArray,
      tableColumnsRole,
      savePackageConfig,
      removeAccentsUpperCaseFormatter,
      sizePerPageLgOptions,
      currentPage,
      sizePerPage,
      totalItems,
      fromItem,
      toItem,
      formatCurrency,
      searchText,
      changeActive,
      createButtonHandle,
      buyButtonHandle,
      buyItem,
      isRoleF2,
      isRoleF1,
      editButtonHandle,
      giftButtonHandle,
      giftItem,
      canModify,
      canGift,
    }
  },
}
</script>

<style lang="">

</style>
