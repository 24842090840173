<template>
  <div id="footer">
    <b-card
      no-body
      class="bg-transparent"
    >
      <div class=" p-1 d-flex justify-content-between align-items-center">
        <div class=" d-flex text-subtitle text-info text-nowrap">
          <span class="mr-1">{{ $t('reservation.total') }}:</span>
          <div>{{ formatCurrency(totalPrice) }}</div>
        </div>
        <b-button
          variant="gradient border-0"
          pill
          :disabled="tabIndexNotSelect === tabIndex"
          @click="handleClick"
        >
          <div v-if="tabIndexNotSelect >= 0 && tabIndexNotSelect !== tabIndex && selectedFlightIndex.length > 1">
            {{ $t('flight.nextFlight') }}
          </div>
          <div v-else>
            Tính giá
          </div>
        </b-button>
      </div>
    </b-card>

    <!-- SECTION 2 : modal-modify-change-flight-price -->
    <b-modal
      id="modal-modify-change-flight-price"
      :title="'2. Xác nhận đổi chuyến bay'"
      title-class="text-heading-3 text-airline font-weight-bolder"
      centered
      no-close-on-backdrop
      no-enforce-focus
      size="lg"
      @show="showPriceHandle"
      @hide="hidePriceHandle"
    >
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill px-2"
          @click="close()"
        >
          QUAY LẠI
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mt-lg-25"
          :variant="isPayNow ? 'success' : 'warning'"
          :disabled="!isConfirm || !priceChange"
          pill
          @click="submitChangeFlightHandle"
        >
          <span class="align-middle">
            {{ isPayNow ? 'THANH TOÁN NGAY' : 'THANH TOÁN SAU' }}
          </span>
        </b-button>
      </template>

      <div class="text-heading-4 mb-50 text-danger">
        {{ $t('reservation.oldFlight') }}:
      </div>

      <!-- ANCHOR - Hành trình Cũ -->
      <b-card
        v-for="(itinerary, index) in itinerariesByIndexSelected"
        :key="index"
        body-class="py-50 py-md-1 px-50 px-md-1"
        class="mb-sm-2 shadow-lg border-danger mx-75 mx-md-3"
      >
        <div
          v-for="(segment, indexSegment) in itinerary"
          :key="indexSegment"
        >
          <div class="d-flex flex-wrap align-items-center font-weight-bolder ml-md-3">
            <span class="mr-50">{{ indexSegment + 1 }}</span>
            <span class="mr-50">{{ segment.airline }}{{ segment.flightNumber }}</span>
            <span class="mr-50">{{ segment.bookingClass }}</span>
            <span class="mr-50">{{ convertISODateTime(segment.departure.at, segment.departure.timeZone).dayMonth }}</span>
            <span class="mr-50">{{ segment.departure.iataCode }}{{ segment.arrival.iataCode }}</span>
            <span class="mr-50">{{ segment.status }}</span>
            <span class="">{{ convertISODateTime(segment.departure.at, segment.departure.timeZone).hourMin }} {{ convertISODateTime(segment.arrival.at, segment.arrival.timeZone).hourMin }}</span>
          </div>
        </div>
      </b-card>

      <!-- ANCHOR - Hành trình Mới -->
      <div class="text-heading-4 mb-50 text-success">
        {{ $t('reservation.newFlight') }}:
      </div>

      <div v-if="!isEmpty(selectedTrip)">
        <b-card
          v-for="(itinerary, index) in selectedTrip.filter(item=> item)"
          :key="index"
          body-class="py-50 py-md-1 px-50 px-md-1"
          class="mb-sm-2 shadow-lg border-success mx-75 mx-md-3"
        >
          <div
            v-for="(segment, indexSegment) in itinerary.segments"
            :key="indexSegment"
          >
            <div class="d-flex flex-wrap align-items-center font-weight-bolder ml-md-3">
              <span class="mr-50">{{ indexSegment + 1 }}</span>
              <span class="mr-50">{{ segment.airline }}{{ segment.flightNumber }}</span>
              <span class="mr-50">{{ ['VN1A_MT', 'VN1A','QH'].includes(bookingData.source)
                ? itinerary.fareOptions.bookingClass.split(' | ')[indexSegment]
                : (itinerary.fareOptions && itinerary.fareOptions.bookingClass) ? itinerary.fareOptions.bookingClass : segment.bookingClass
              }}</span>
              <span class="mr-50">{{ convertISODateTime(segment.departure.at, segment.departure.timezone).dayMonth }}</span>
              <span class="mr-50">{{ segment.departure.IATACode }}{{ segment.arrival.IATACode }}</span>
              <span
                v-if="segment.status"
                class="mr-50"
              >{{ segment.status }}</span>
              <span class="mr-50">{{ convertISODateTime(segment.departure.at, segment.departure.timezone).hourMin }}</span>
              <span class="">{{ convertISODateTime(segment.arrival.at, segment.arrival.timezone).hourMin }}</span>
            </div>
          </div>
        </b-card>
      </div>

      <!-- ANCHOR - [ 'QH', '1A' ] -->
      <div v-if="['QH', '1A'].includes(bookingData?.source)">
        <div
          v-if="priceChange"
          class="text-dark font-weight-bold font-medium-2 d-flex justify-content-end align-items-center"
        >
          <span class="mr-2">{{ $t('reservation.totalAmountNeedToPay') }}:</span>
          <span class="text-warning font-weight-bolder font-medium-4 mr-1">{{ formatCurrency(priceChange.reduce((total, item) => total + item.pricingInfo.total, 0) ) }} (VND)</span>
        </div>
        <div v-else-if="errorChange">
          <div class="text-danger text-heading-4">
            {{ $t('reservation.errorChangeFlight') }}
          </div>
          <div>{{ errorChange }}</div>
        </div>
        <div v-else-if="priceChange === false">
          {{ $t('reservation.calcPriceForChangeFlight') }}...
          <p class="text-center">
            <b-spinner
              variant="info"
              small
            />
          </p>
        </div>
      </div>

      <!-- ANCHOR - [ VJ ] -->
      <div v-if="['VJ'].includes(bookingData?.source)">
        <BCard
          v-if="priceChange"
          header-bg-variant="light-success"
          header-class="py-50"
          body-class="mt-75 pb-75"
          class="border mb-50"
        >
          <template #header>
            <div class="w-100 d-flex-between font-weight-bolder">
              <span>Tính giá thành công</span>
              <BButton
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-warning"
                class="btn-icon rounded-lg"
                @click="() => isHideSomePriceField = !isHideSomePriceField"
              >
                <feather-icon
                  v-if="isHideSomePriceField"
                  icon="EyeIcon"
                  size="16"
                />
                <feather-icon
                  v-else
                  icon="EyeOffIcon"
                  size="16"
                />
              </BButton>
            </div>
          </template>

          <BRow
            v-if="priceChange.oldPrice && !isHideSomePriceField"
            class="mx-0 mb-25"
          >
            <BCol
              cols="6"
              class="text-right"
            >
              Giá vé cũ:
            </BCol>
            <BCol
              cols="6"
              class="text-right"
            >
              {{ formatCurrency(priceChange.oldPrice) }} (VND)
            </BCol>
          </BRow>

          <BRow class="mx-0 mb-25">
            <BCol
              cols="6"
              class="text-right"
            >
              Phí đổi vé:
            </BCol>
            <BCol
              cols="6"
              class="text-right"
            >
              {{ formatCurrency(priceChange.fee) }} (VND)
            </BCol>
          </BRow>

          <BRow class="mx-0 mb-25 align-items-center">
            <BCol
              cols="6"
              class="text-right"
            >
              Tiền chênh lệch hành trình mới:
            </BCol>
            <BCol
              cols="6"
              class="text-right"
            >
              {{ formatCurrency(priceChange.difference) }} (VND)
            </BCol>
          </BRow>

          <BRow class="mx-0 mb-25">
            <BCol
              cols="6"
              class="text-right"
            >
              <span class="fw-700 text-danger">Tổng tiền cần thanh toán:</span>
            </BCol>
            <BCol
              cols="6"
              class="text-right text-danger font-medium-2 fw-700"
            >
              {{ formatCurrency(priceChange.total) }} (VND)
            </BCol>
          </BRow>

          <BRow
            v-if="!isHideSomePriceField"
            class="mx-0 mb-25"
          >
            <BCol
              cols="6"
              class="text-right"
            >
              Tổng tiền vé sau thanh toán:
            </BCol>
            <BCol
              cols="6"
              class="text-right text-warning font-medium-2 fw-700"
            >
              {{ formatCurrency(priceChange.newPrice) }} (VND)
            </BCol>
          </BRow>
        </BCard>

        <div v-else-if="errorChange">
          <div class="text-danger text-heading-4">
            {{ $t('reservation.errorChangeFlight') }}
          </div>
          <div>{{ errorChange }}</div>
        </div>

        <div v-else-if="priceChange === false">
          {{ $t('reservation.calcPriceForChangeFlight') }}...
          <p class="text-center">
            <b-spinner variant="info" />
          </p>
        </div>

        <b-alert
          :show="['VJ'].includes(bookingData?.source)"
          variant="danger "
          class="fw-700 p-75 text-center mt-1"
        >
          <div>Lưu ý vé có hành lý (dịch vụ) đi kèm vui lòng kiểm tra lại booking, (có thể bị rớt hành lý sau khi đổi).</div>
        </b-alert>
      </div>

      <!-- ANCHOR - [ VN1A_MT, VN1A ] -->
      <div v-if="['VN1A_MT', 'VN1A' ].includes(bookingData?.source) && !['HOLD'].includes(bookingData?.status)">
        <BCard
          v-if="priceChange"
          header-bg-variant="light-success"
          header-class="py-50"
          body-class="mt-75 pb-75"
          class="border mb-50"
        >
          <template #header>
            <div class="w-100 d-flex-between font-weight-bolder">
              <span>Tính giá thành công</span>
              <BButton
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-warning"
                class="btn-icon rounded-lg"
                @click="() => isHideSomePriceField = !isHideSomePriceField"
              >
                <feather-icon
                  v-if="isHideSomePriceField"
                  icon="EyeIcon"
                  size="16"
                />
                <feather-icon
                  v-else
                  icon="EyeOffIcon"
                  size="16"
                />
              </BButton>
            </div>
          </template>

          <BRow
            v-if="priceChange.oldPrice && !isHideSomePriceField"
            class="mx-0 mb-25"
          >
            <BCol
              cols="6"
              class="text-right"
            >
              Giá vé cũ:
            </BCol>
            <BCol
              cols="6"
              class="text-right"
            >
              {{ formatCurrency(priceChange.oldPrice) }} (VND)
            </BCol>
          </BRow>

          <BRow class="mx-0 mb-25">
            <BCol
              cols="6"
              class="text-right"
            >
              Phí đổi vé:
            </BCol>
            <BCol
              cols="6"
              class="text-right"
            >
              {{ formatCurrency(priceChange.fee) }} (VND)
            </BCol>
          </BRow>

          <BRow class="mx-0 mb-25 align-items-center">
            <BCol
              cols="6"
              class="text-right"
            >
              Tiền chênh lệch hành trình mới:
            </BCol>
            <BCol
              cols="6"
              class="text-right"
            >
              {{ formatCurrency(priceChange.newPrice - (priceChange.oldPrice + priceChange.fee)) }} (VND)
            </BCol>
          </BRow>

          <BRow class="mx-0 mb-25">
            <BCol
              cols="6"
              class="text-right"
            >
              <span class="fw-700 text-danger">Tổng tiền cần thanh toán:</span>
            </BCol>
            <BCol
              cols="6"
              class="text-right text-danger font-medium-2 fw-700"
            >
              <!-- {{ priceChange.total }} -->
              {{ formatCurrency(priceChange.newPrice - priceChange.oldPrice) }} (VND)
            </BCol>
          </BRow>

          <BRow
            v-if="!isHideSomePriceField"
            class="mx-0 mb-25"
          >
            <BCol
              cols="6"
              class="text-right"
            >
              Tổng tiền vé sau thanh toán:
            </BCol>
            <BCol
              cols="6"
              class="text-right text-warning font-medium-2 fw-700"
            >
              {{ formatCurrency(priceChange.newPrice) }} (VND)
            </BCol>
          </BRow>
        </BCard>

        <div v-else-if="errorChange">
          <div class="text-danger text-heading-4">
            {{ $t('reservation.errorChangeFlight') }}
          </div>
          <div>{{ errorChange }}</div>
        </div>

        <div v-else-if="priceChange === false">
          {{ $t('reservation.calcPriceForChangeFlight') }}...
          <p class="text-center">
            <b-spinner variant="info" />
          </p>
        </div>

        <b-alert
          :show="['VJ'].includes(bookingData.source)"
          variant="danger "
          class="fw-700 p-75 text-center mt-1"
        >
          <div>Lưu ý vé có hành lý (dịch vụ) đi kèm vui lòng kiểm tra lại booking, (có thể bị rớt hành lý sau khi đổi).</div>
        </b-alert>
      </div>

      <!-- ANCHOR - [ VN1A_MT, VN1A ] VÉ HOLD -->
      <div v-if="['VN1A_MT', 'VN1A' ].includes(bookingData?.source) && ['HOLD'].includes(bookingData?.status)">
        <b-alert
          show
          variant="warning"
          class="fw-700 p-75 text-center mt-1"
        >
          <div>Vui lòng tính giá lại sau khi thêm chuyến bay để có thể lấy giá chính xác nhất.</div>
        </b-alert>
      </div>

      <CardAncillaryPayOptions
        v-if="bookingData && ['VJ'].includes(bookingData.source)"
        :reservations-data="bookingData"
        :is-pay-now.sync="isPayNow"
        :has-paid-trip="hasPaidTrip"
        class="mt-2"
      />

      <div class="mt-1">
        <IAmConfirm :is-confirm.sync="isConfirm" />
      </div>
    </b-modal>
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, BSpinner, BAlert, BRow, BCol,
} from 'bootstrap-vue'
import {
  toRefs, watch, ref, computed,
} from '@vue/composition-api'
import {
  compact, capitalize, groupBy, isEmpty,
} from 'lodash-es'
import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'

import i18n from '@/libs/i18n'
import IAmPaymentPassword from '@/components/IAmPaymentPassword.vue'
import store from '@/store'
import { getUserData } from '@/api/auth/utils'
import { resolveTitle } from '@/constants/selectOptions'
import { apiBooking } from '@/api'

import { validatorPaymentPassword } from '@core/utils/validations/validators'
import {
  formatCurrency,
  convertISODateTime,
} from '@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'
import useReservationDetailBookingHandle from '@reservation/reservation-modify/components/detail/flights-details/useReservationDetailBookingHandle'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BSpinner,
    BAlert,
    BRow,
    BCol,

    IAmConfirm: () => import('@/components/IAmConfirm.vue'),
    CardAncillaryPayOptions: () => import('@reservation/reservation-modify/components/detail/components/CardAncillaryPayOptions.vue'),
  },
  props: {
    selectedTrips: {
      type: Array,
      default: () => [],
    },
    bookingData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { root }) {
    const { toastError, toastSuccess } = useToast()
    const { bookingData } = toRefs(props)

    const {
      tabIndex,
      tempSelectedTrips,
      selectedFlightIndex,
      itinerariesByIndexSelected,
      convertToISO8601Date,
    } = useReservationDetailBookingHandle()

    const {
      changeFlights,
      changeFlightsPrice,
    } = useReservationHandle()

    const isHideSomePriceField = ref(true)

    const selectedTrip = toRefs(props).selectedTrips
    const priceChange = ref()
    const errorChange = ref()
    const totalPrice = ref(0)
    const indexSelectedFlightToCancel = ref([])
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    const indexSelectedFlightToCancelComputed = computed(() => indexSelectedFlightToCancel.value.sort())
    const isConfirm = ref(false)
    const payloadChangeFlight = ref(null)

    const isPayNow = ref(false)
    const hasPaidTrip = ref(false)

    const airline = computed(() => {
      if (isEmpty(itinerariesByIndexSelected.value)) return ''
      return itinerariesByIndexSelected.value[0][0]?.airline || ''
    })

    watch(selectedTrip, () => {
      const tripPrice = selectedTrip.value.reduce((acc, current) => {
        const cur = current ? current.fareOptions.afterMultiple.total : 0
        return acc + cur
      }, 0)
      totalPrice.value = tripPrice - bookingData.value.refundAmountIdentification
    })

    const tabIndexNotSelect = computed(() => {
      const a = selectedTrip.value.findIndex(item => item === null)
      // console.log({ a, selectedTrip: selectedTrip.value })
      return a
    })
    const oldItineraries = computed(() => store.getters['app-reservation/getOldItineraries'])
    const airlineSelect = ref(bookingData.value.airlines.split(' | ')[0])

    function handleClick() {
      if (tabIndexNotSelect.value >= 0 && selectedFlightIndex.value.length > 1) {
        store.dispatch('app-reservation/setTabIndex', tabIndexNotSelect.value)
      } else {
        store.dispatch('app-reservation/setSelectedTrip', selectedTrip.value)
        this.$bvModal.show('modal-modify-change-flight-price')
      }
    }

    function getFareBreakdownsOldItineraries() {
      const result = []
      const passengerByPaxType = groupBy(bookingData.value.paxLists, 'paxType')
      for (const paxType in passengerByPaxType) {
        if (Object.prototype.hasOwnProperty.call(passengerByPaxType, paxType)) {
          result.push({
            paxType,
            netFare: ['VN1A', 'VN1A_MT'].includes(bookingData.value.source) ? (passengerByPaxType[paxType][0]?.pricingInfo?.netFare) : 0,
            discountAmount: 0,
            tax: ['VN1A', 'VN1A_MT'].includes(bookingData.value.source) ? (passengerByPaxType[paxType][0]?.pricingInfo?.tax) : 0,
            total: ['VN1A', 'VN1A_MT'].includes(bookingData.value.source) ? (passengerByPaxType[paxType][0]?.pricingInfo?.total) : 0,
            fareValue: '',
          })
        }
      }
      return result
    }

    const getNewItineraries = ({ paxLists }, trips) => {
      const itinerariesArr = []
      trips.forEach((trip, tripIndex) => {
        const {
          fareOptions, clientId, bookingKey, source,
        } = trip
        itinerariesArr.push({
          source,
          clientId,
          itineraryId: (airlineSelect.value === 'VJ') ? itinerariesByIndexSelected.value[0][0].itineraryId : undefined,
          bookingKey: bookingKey || '',
          fareBreakdowns: ['ADULT', 'CHILD', 'INFANT'].map(paxType => {
            const filteredPassengers = paxLists.filter(passenger => passenger.paxType === paxType)
            const totalPassengers = filteredPassengers.length
            return {
              paxType,
              netFare: ['VN1A', 'VN1A_MT', 'VJ'].includes(source) ? trip.fareOptions[`fare${capitalize(paxType)}`] : 0,
              ...(['VN1A', 'VN1A_MT', 'VJ'].includes(source) && { discountAmount: trip.fareOptions[`discount${capitalize(paxType)}`] }),
              tax: ['VN1A', 'VN1A_MT', 'VJ'].includes(source) ? trip.fareOptions[`tax${capitalize(paxType)}`] : 0,
              total: ['VN1A', 'VN1A_MT', 'VJ'].includes(source) ? trip.fareOptions[`total${capitalize(paxType)}`] : totalPassengers,
              fareValue: fareOptions.fareValue,
            }
          }).filter(p => p.total),
          segments: trip.segments.map(segment => ({
            leg: segment.leg,
            airline: segment.airline,
            departure: segment.departure.IATACode,
            arrival: segment.arrival.IATACode,
            departureTime: convertISODateTime(segment.departure.at, segment.departure.timezone).ISOdatetime,
            arrivalTime: convertISODateTime(segment.arrival.at, segment.arrival.timezone).ISOdatetime,
            flightNumber: segment.flightNumber.toString(),
            fareType: fareOptions?.fareType.split(' | ')[segment.leg - 1]?.toString() || fareOptions?.fareType || '',
            fareBasisCode: fareOptions?.fareBasisCode?.split(' | ')[segment.leg - 1]?.toString() || fareOptions?.fareBasisCode,
            bookingClass: fareOptions?.bookingClass?.split(' | ')[segment.leg - 1]?.toString() || fareOptions?.bookingClass || '',
            groupClass: fareOptions?.groupClass || '',
            segmentId: segment?.segmentId || segment?.segmentValue || '',
            marriageGrp: segment.marriageGrp,
            segmentValue: source === 'VJ' ? itinerariesByIndexSelected.value[0][0].segmentValue : segment.segmentValue,
            ...(source === 'VJ' && { segmentId: itinerariesByIndexSelected.value[0][0].segmentId }),
          })),
        })
      })
      return itinerariesArr
    }

    const getDataPax = booking => booking.paxLists.map(passenger => ({
      firstName: passenger.firstName,
      lastName: passenger.lastName,
      title: passenger.title ? passenger.title : resolveTitle(passenger.firstName.split(' ').splice(-1)[0]),
      birthday: passenger.birthday ? convertToISO8601Date(passenger.birthday) : null,
      paxType: passenger.paxType,
      ...((passenger.paxType === 'INFANT') && { parentPaxId: passenger.parentPaxId }),
      paxId: passenger.paxId,
      pricingInfo: passenger.pricingInfo,
      PaxDocuments: [],
    }))

    function showPriceHandle() { // Modal 2
      isConfirm.value = false
      payloadChangeFlight.value = null
      priceChange.value = false
      errorChange.value = null

      if (((['VJ'].includes(bookingData.value.source) && (bookingData.value.totalAmountToBePaid === 0))
      || ['QH'].includes(bookingData.value.source))
      || (['VN1A_MT', 'VN1A'].includes(bookingData.value.source) && !['HOLD'].includes(bookingData.value.status))
      ) {
        hasPaidTrip.value = true
        isPayNow.value = true
      } else {
        isPayNow.value = false
      }

      const payload = {
        contact: getUserData().employeeData.id,
        airline: bookingData.value.airlines.split(' | ')[0],
        source: bookingData.value.source,
        bookingId: bookingData.value?.bookingInfoId || null,
        agencyCode: bookingData.value.agency,
        paxCode: bookingData.value.paxContact.code,
        pnrNumber: bookingData.value.bookingCode,
        isCancel: false,
        isUnpaid: ['VN1A', 'VN1A_MT'].includes(bookingData.value.source) ? ['HOLD'].includes(bookingData.value.status) : undefined,
        itineraries: getNewItineraries(bookingData.value, compact(tempSelectedTrips.value)),
        oldItineraries: oldItineraries.value.length ? oldItineraries.value.map(segments => ({
          source: bookingData.value?.source,
          clientId: '',
          bookingKey: '',
          itineraryId: segments[0]?.itineraryId || null,
          fareBreakdowns: getFareBreakdownsOldItineraries(),
          segments: segments.map(segment => ({
            leg: segment.leg,
            segmentId: segment?.segmentId || null,
            airline: segment.airline,
            departure: segment.departure.iataCode,
            arrival: segment.arrival.iataCode,
            departureTime: convertISODateTime(segment.departure.at, segment.departure.timeZone).ISOdatetime,
            arrivalTime: convertISODateTime(segment.arrival.at, segment.arrival.timeZone).ISOdatetime,
            flightNumber: ['VN1A_MT', 'VN1A'].includes(bookingData.value.source) ? segment.flightNOP : segment.flightNumber,
            fareType: segment.fareType || '',
            fareBasisCode: '',
            bookingClass: segment.bookingClass || '',
            groupClass: segment.groupClass || '',
            marriageGrp: segment.marriageGrp,
            segmentValue: segment.segmentValue || '',
          })),
        })) : null,
      }

      if (['1A'].includes(bookingData.value?.source)) {
        payload.paxLists = getDataPax(bookingData.value)
        payload.type = bookingData.value?.source
        payload.flightType = bookingData.value?.flightType || ''

        apiBooking.confirmPrice(payload)
          .then(res => {
            priceChange.value = res.paxLists

            payloadChangeFlight.value = {
              ...payload,
              paxList: res.paxLists.map(passenger => ({
                ...passenger,
                PaxDocuments: [],
                parentPaxId: passenger.parentPaxId,
              })),
              bookingId: res?.bookingId,
            }
          })
          .catch(error => {
            errorChange.value = error?.message || error
          })
      } else {
        payload.paxList = getDataPax(bookingData.value)
        changeFlightsPrice(payload)
          .then(res => {
            if (payload.airline === 'QH') {
              priceChange.value = res.paxLists
              payloadChangeFlight.value = {
                ...payload,
                paxList: res.paxLists.map(passenger => ({
                  ...passenger,
                  PaxDocuments: [],
                  parentPaxId: passenger.parentPaxId,
                })),
                bookingId: res?.bookingId,
              }
            } else if (['VN1A_MT', 'VN1A'].includes(payload.source)) {
              priceChange.value = { ...res.chargeAmount }

              payloadChangeFlight.value = {
                ...payload,
                session: res.header,
                exchangeTickets: bookingData.value.paxLists
                  .map(p => p.eticket
                    .filter(et => ['OPEN'].includes(et.status))
                    .map(e => ({
                      paxId: e.paxId,
                      ticketNumber: e.number,
                    })))
                  .flat(2),
                bookingId: res?.bookingId,
              }
            } else { // NOTE - VJ ONLY
              priceChange.value = { ...res.chargeAmount }
              payloadChangeFlight.value = payload
            }
          })
          .catch(error => {
            errorChange.value = error?.message || error
          })
      }
    }

    function hidePriceHandle() {
      isPayNow.value = false
      hasPaidTrip.value = false
    }

    const getMeEnablePaymentPassword = computed(() => store.getters['userStore/getMeEnablePaymentPassword'])

    // NOTE - Change-flight - PRICE
    async function submitChangeFlightHandle() {
      const payload = cloneDeep(payloadChangeFlight.value)

      // if (['VJ'].includes(bookingData.value.source)) {
      //   payload.isPaymentImmediate = isPayNow.value
      // }

      let paymentPassword
      if (isPayNow.value) {
        payload.isPaymentImmediate = true
        if (getMeEnablePaymentPassword.value) {
          paymentPassword = await Vue.swal({
            title: this.$t('myAccount.paymentPassword.title'),
            html: '<div id="i-am-payment-password"></div>',
            didOpen: () => {
              new Vue({
                render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'off' } }),
                i18n,
              }).$mount('#i-am-payment-password')
            },
            focusConfirm: false,
            allowOutsideClick: true,
            preConfirm: () => new Promise(resolve => {
              const pwd = document.getElementById('swal-input-payment-password-1').value
              if (!validatorPaymentPassword(pwd)) {
                Vue.swal.showValidationMessage(this.$t('myAccount.paymentPassword.errorInput'))
              } else { resolve(pwd) }
            }),
            showCancelButton: true,
            confirmButtonText: this.$t('confirmation'),
            cancelButtonText: this.$t('cancel'),
            buttonsStyling: true,
            customClass: {
              confirmButton: 'btn btn-gradient',
              cancelButton: 'btn btn-danger mr-1',
            },
            reverseButtons: true,
          })
          if (paymentPassword.isDenied || paymentPassword.isDismissed) {
            return
          }

          payload.paymentPassword = paymentPassword?.value || ''
        }
      }

      store.dispatch('app/setLoading', true)
      changeFlights(payload)
        .then(() => {
          toastSuccess({
            title: 'messagesList.success',
            content: 'Thay đổi hành trình thành công',
          })
          Array.from(window.document.querySelectorAll('.modal.show'))
            .map(el => el.id)
            .forEach(id => {
              root.$root.$emit('bv::hide::modal', id)
            })
        })
        .catch(error => {
          console.error(error)
          toastError({
            title: 'messagesList.error',
            content: 'Có lỗi xảy ra khi thực hiện thay đổi hành trình.',
          })
        })
        .finally(() => {
          store.dispatch('app/setLoading', false)
        })
    }

    return {
      selectedTrip,
      itinerariesByIndexSelected,
      handleClick,
      formatCurrency,
      convertISODateTime,
      totalPrice,
      tabIndexNotSelect,
      tabIndex,
      selectedFlightIndex,
      showPriceHandle,
      submitChangeFlightHandle,
      isEmpty,
      priceChange,
      errorChange,
      indexSelectedFlightToCancelComputed,
      isConfirm,
      airline,
      isHideSomePriceField,

      isPayNow,
      hasPaidTrip,
      hidePriceHandle,
    }
  },
}
</script>

<style lang="scss" scoped>
#footer ::v-deep {
  position: sticky;
  bottom: 0;
  z-index: 10;

  .bg-transparent {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
  }
}

.margin-checkbox-label::v-deep {
  .custom-control-label {
    margin-top: 4px !important;
  }
}
</style>
