var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-special-services",
      "title": _vm.$t('reservation.specialServices.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder mb-0",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.handleShowModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25  border-0",
          attrs: {
            "disabled": !_vm.isConfirm || _vm.isDisabledSubmit,
            "pill": ""
          },
          on: {
            "click": _vm.confirmAddSsrsHandle
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('reservation.specialServices.book')))])])];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading,
      "spinner-variant": 'info'
    }
  }, [_c('div', {
    staticClass: "text-heading-5 mb-25 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.specialServices.itineraries.title')) + " ")]), _c('b-form-group', {
    staticClass: "ml-50 mb-0",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('b-form-checkbox', {
          staticClass: "custom-checkbox-label",
          attrs: {
            "aria-describedby": "flights",
            "aria-controls": "flights"
          },
          on: {
            "change": _vm.itinerariesToggleAll
          },
          model: {
            value: _vm.itinerariesAllSelected,
            callback: function callback($$v) {
              _vm.itinerariesAllSelected = $$v;
            },
            expression: "itinerariesAllSelected"
          }
        }, [_c('div', {
          staticClass: "text-body-2"
        }, [_vm._v(" " + _vm._s(_vm.itinerariesAllSelected ? _vm.$t('reservation.unselectAll') : _vm.$t('reservation.selectAll')) + " ")])])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref2) {
        var ariaDescribedby = _ref2.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          staticClass: "ml-50 ml-sm-1",
          attrs: {
            "id": "checkbox-group-cancel-flight-vn1a",
            "aria-describedby": ariaDescribedby,
            "stacked": "",
            "name": "flights"
          },
          model: {
            value: _vm.itinerariesSelected,
            callback: function callback($$v) {
              _vm.itinerariesSelected = $$v;
            },
            expression: "itinerariesSelected"
          }
        }, _vm._l(_vm.segmentList, function (segment) {
          return _c('div', {
            key: segment.index,
            staticClass: "d-flex "
          }, [_c('b-form-checkbox', {
            staticClass: "custom-checkbox-label mb-50",
            attrs: {
              "value": segment
            }
          }, [_c('span', {
            staticClass: "text-heading-4"
          }, [_vm._v(_vm._s(segment.segmentText))])])], 1);
        }), 0)];
      }
    }])
  }), _c('div', {
    staticClass: "text-heading-5 mb-25 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.specialServices.passengers.title')) + " ")]), _c('b-form-group', {
    staticClass: "ml-50 mb-0",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm.bookingData.paxLists.length > 1 ? _c('b-form-checkbox', {
          attrs: {
            "aria-describedby": "flights",
            "aria-controls": "flights"
          },
          on: {
            "change": _vm.passengersToggleAll
          },
          model: {
            value: _vm.passengersAllSelected,
            callback: function callback($$v) {
              _vm.passengersAllSelected = $$v;
            },
            expression: "passengersAllSelected"
          }
        }, [_c('div', {
          staticClass: "text-body-2"
        }, [_vm._v(" " + _vm._s(_vm.passengersAllSelected ? _vm.$t('reservation.unselectAll') : _vm.$t('reservation.selectAll')) + " ")])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref3) {
        var ariaDescribedby = _ref3.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          staticClass: "ml-50 ml-sm-1 mb-1",
          attrs: {
            "aria-describedby": ariaDescribedby,
            "stacked": "",
            "name": "passengers"
          },
          model: {
            value: _vm.passengersSelected,
            callback: function callback($$v) {
              _vm.passengersSelected = $$v;
            },
            expression: "passengersSelected"
          }
        }, _vm._l(_vm.bookingData.paxLists, function (passenger, indexPassenger) {
          return _c('div', {
            key: indexPassenger,
            staticClass: "d-flex flex-column my-25"
          }, [_c('b-form-checkbox', {
            attrs: {
              "value": passenger.paxId
            }
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            staticClass: "mr-50 text-heading-4"
          }, [_vm._v(" " + _vm._s("".concat(passenger.paxId, " ").concat(passenger.lastName, " ").concat(passenger.firstName, " ").concat(passenger.title)) + " ")])])])], 1);
        }), 0)];
      }
    }])
  }), _c('div', {
    staticClass: "text-heading-5 mb-25 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.specialServices.ss.title')) + " ")]), _c('v-select', {
    staticClass: "ml-50",
    attrs: {
      "options": _vm.specialServicesListVN1A,
      "reduce": function reduce(val) {
        return val;
      },
      "selectable": function selectable(option) {
        return !option.isGroup;
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "text-info fw-700"
        }, [_vm._v(" " + _vm._s(data.value) + " ")]), _c('div', {
          staticClass: "ml-1 text-wrap"
        }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
      }
    }, {
      key: "option",
      fn: function fn(data) {
        return [_c('div', {
          class: "d-flex ".concat(data.isGroup ? 'mt-1' : '')
        }, [_c('div', {
          class: "fw-700 ".concat(_vm.specialServiceSelected && data.value === _vm.specialServiceSelected.value ? 'text-white' : 'text-info')
        }, [_vm._v(" " + _vm._s(data.value) + " ")]), _c('div', {
          class: "".concat(data.value ? 'ml-1' : '', " text-wrap")
        }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('flight.noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.specialServiceSelected,
      callback: function callback($$v) {
        _vm.specialServiceSelected = $$v;
      },
      expression: "specialServiceSelected"
    }
  }), !_vm.isDisabledSubmit ? _c('IAmConfirm', {
    attrs: {
      "is-confirm": _vm.isConfirm
    },
    on: {
      "update:isConfirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      },
      "update:is-confirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      }
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }