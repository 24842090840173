<template>
  <b-modal
    id="import-modal"
    :visible="showImportModal"
    header-bg-variant="light"
    shadow
    style="background: #fff;"
    size="lg"
    backdrop
    centered
    no-close-on-backdrop
    req
    hide-footer
    @hide="closeModalCustomer"
  >
    <template #modal-header>
      <div v-if="readonlyModalDetail=== false">
        <span style="font-size: large; font-weight: 800;">
          {{ mode === 'edit' ? $t('customer.updateCustomer') : $t('customer.addCustomerTitle') }}
        </span>
      </div>
      <div v-else>
        <span style="font-size: large; font-weight: 800;">{{ $t('customer.detailsCustomer') }}</span>
      </div>

      <b-button
        style="background:none !important; border: none !important;"
        @click="closeModalCustomer"
      >
        <feather-icon
          style="color: black;"
          icon="XIcon"
        />
      </b-button>
    </template>
    <validation-observer
      #default="{ invalid }"
      ref="refFormObserver"
    >
      <b-card-modal-customer
        :dataCustomerEdit="dataToEdit"
        :readonlys="readonly"
        :mode="mode"
        @add-customer-data-modal="(data)=>hanldeAddDataCustomerModal(data)"
      />
      <!-- Footer tùy chỉnh -->
      <div v-if="readonlyModalDetail=== false">
        <div class="d-flex justify-content-end gap-2">
          <b-button
            variant="warning"
            class="d-flex justify-content-end gap-1"
            @click="mode === 'edit' ? toggleReadonly(): $emit('back')"
          >
            <div v-if="mode === 'edit'">
              <feather-icon
                icon="RefreshCwIcon"
                class="mr-50"
              />
            </div>

            <div
              v-else
              class="d-flex justify-content-end"
            >
              <feather-icon
                icon="RotateCcwIcon"
                class="mr-50"
              />
            </div>
            {{ mode === 'edit' ? $t('customer.edit') : $t('customer.Back') }}
          </b-button>
          <b-button
            variant="warning"
            :disabled="readonly || invalid "
            @click="handleSave()"
          >
            <feather-icon
              icon="CheckCircleIcon"
              class="mr-50"
            />
            {{ mode === 'edit' ? $t('customer.save') : $t('customer.moreDropdown.add') }}
          </b-button>
        </div>
      </div>
      <div
        v-else
        class="d-flex justify-content-end"
      >
        <div class="d-flex justify-content-end gap-2">
          <b-button
            variant="secondary"

            class="d-flex justify-content-end gap-1"
            @click="mode === 'edit' ? toggleReadonly(): $emit('back')"
          >
            <div v-if="mode === 'edit'">
              <feather-icon
                icon="RefreshCwIcon"
                class="mr-50"
              />
            </div>

            <div
              v-else
              class="d-flex justify-content-end"
            >
              <feather-icon
                icon="RotateCcwIcon"
                class="mr-50"
              />
            </div>
            {{ mode === 'edit' ? $t('customer.edit') : $t('customer.Back') }}
          </b-button>
          <b-button
            variant="warning"
            :disabled="readonly || invalid "
            @click="handleSave()"
          >
            <feather-icon
              icon="CheckCircleIcon"
              class="mr-50"
            />
            {{ mode === 'edit' ? $t('customer.save') : $t('customer.moreDropdown.add') }}
          </b-button>
        </div>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, watch, watchEffect } from '@vue/composition-api'
import { BButton, BModal } from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate'

import { apiCustomer } from '@/api'

import { getDifference } from '@core/utils/utils'

import useToast from '@useToast'

import BCardModalCustomer from './BCardModalCustomer.vue'

export default {
  components: {
    BCardModalCustomer,
    BButton,
    BModal,
    ValidationObserver,

  },
  props: {
    readonlyModalDetail: {
      type: Boolean,
      default: false,
    },
    showImportModal: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'edit',
    },
    editCustomerModal: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const readonly = ref(props.readonlyModalDetail)
    const dataToEdit = ref(
      cloneDeep(props.editCustomerModal),
    )
    const dataToAdd = ref({})
    const { toastSuccess, toastError } = useToast()

    watch(
      () => props.editCustomerModal,
      newValue => {
        if (props.mode === 'edit') {
          dataToEdit.value = cloneDeep(newValue)
        }
      }, { deep: true },
    )
    const hanldeAddDataCustomerModal = data => {
      dataToAdd.value = data
    }
    const toggleReadonly = () => {
      if (props.mode === 'edit') {
        readonly.value = !readonly.value }
      // } else {
      //   showImportModal.value === false
      // }
    }
    const toggleOk = () => {
      emit('ok-detail')
    }
    const closeModalCustomer = () => {
      emit('close-modal')
    }
    const handleSave = async () => {
      try {
        if (props.mode === 'edit') {
          const dataToUpdate = getDifference(dataToAdd.value, props.editCustomerModal)
          const res = await apiCustomer.updateCustomer(dataToEdit.value.id, dataToUpdate)
          toastSuccess({ title: 'Success', content: 'Customer updated successfully!' })
          emit('refetch-data', res)
        } else {
          const res = await apiCustomer.addCustomer(dataToAdd.value)
          toastSuccess({ title: 'Success', content: 'Customer added successfully!' })
          emit('refetch-data', res)
        }
        emit('update:showImportModal', false)
      } catch (error) {
        console.error('Error:', error)
        toastError({ title: 'Error', content: 'Operation failed!' })
      }
    }

    return {
      dataToEdit,
      readonly,
      toggleReadonly,
      handleSave,
      closeModalCustomer,
      hanldeAddDataCustomerModal,
      toggleOk,
    }
  },
}
</script>
