<template>
  <div>
    <!-- ANCHOR modal form-package-config-modal. -->
    <b-modal
      id="form-package-config-modal"
      size="xl"
      :centered="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-footer="true"
      :title="$t(`packageConfig.form.${data.id ? `update`:`create`}`)"
      @show="onShow"
      @hide="onHide"
    >
      <validation-observer
        #default="{ handleSubmit, invalid, pristine }"
        ref="refFormObserver"
      >
        <IAmOverlay :loading="loading">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row align-horizontally="start">
              <b-col md="6">
                <b-row>
                  <!-- ANCHOR input name -->
                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      :name="$t('packageConfig.form.name')"
                      vid="name"
                      rules="required"
                    >
                      <b-form-group label-for="name">
                        <template #label>
                          {{ $t('packageConfig.form.name') }}
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="name"
                          v-model="data.name"
                          :state="getValidationState(validationContext)"
                          :placeholder="$t('packageConfig.form.name')"
                          :disabled="false"
                        />
                        <b-form-invalid-feedback v-if="validationContext.errors.length">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR input price -->
                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      :name="$t('packageConfig.form.price')"
                      vid="price"
                      rules="required"
                    >
                      <b-form-group label-for="price">
                        <template #label>
                          {{ $t('packageConfig.form.price') }}
                          <span class="text-danger">*</span>
                        </template>
                        <IAmInputMoney
                          id="price"
                          v-model="data.price"
                          v-remove-non-numeric-chars.allNumber
                          :value-money.sync="data.price"
                          :state="getValidationState(validationContext)"
                          :placeholder="$t('packageConfig.form.price')"
                          :disabled="false"
                          :require="true"
                        />
                        <b-form-invalid-feedback v-if="validationContext.errors.length">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR select unit -->
                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      :name="$t('packageConfig.form.unit')"
                      vid="unit"
                      rules="required|required|min:1"
                    >
                      <b-form-group
                        label-for="unit"
                        :state="getValidationState(validationContext)"
                      >
                        <template #label>
                          {{ $t('packageConfig.form.unit') }}
                          <span
                            v-if="true"
                            class="text-danger"
                          >*</span>
                        </template>
                        <v-select
                          id="unit"
                          v-model="data.unit"
                          :options="units"
                          :clearable="false"
                          :placeholder="$t('packageConfig.form.unit')"
                        >
                          <template #option="data">
                            <span>
                              {{ $t(`packageConfig.units.${data.label}`) }}
                            </span>
                          </template>
                          <template #selected-option="data">
                            <span>
                              {{ $t(`packageConfig.units.${data.label}`) }}
                            </span>
                          </template>
                          <template #no-options>
                            {{ $t('noOptions') }}
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR input searchLimit -->
                  <b-col md="6">
                    <b-form-group label-for="searchLimit">
                      <template #label>
                        {{ $t('packageConfig.form.searchLimit') }}
                      </template>
                      <b-form-input
                        id="searchLimit"
                        v-model="data.searchLimit"
                        v-remove-non-numeric-chars.allNumber
                        :name="'search'"
                        :placeholder="$t('packageConfig.form.searchLimit')"
                        number
                        :disabled="false"
                        :required="false"
                        :state="null"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- ANCHOR input bookingLimite -->
                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      :name="$t('packageConfig.form.bookingLimit')"
                      vid="bookingLimite"
                      rules="required"
                    >
                      <b-form-group label-for="bookingLimite">
                        <template #label>
                          {{ $t('packageConfig.form.bookingLimit') }}
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="bookingLimite"
                          v-model="data.bookingLimit"
                          v-remove-non-numeric-chars.allNumber
                          number
                          :state="getValidationState(validationContext)"
                          :placeholder="$t('packageConfig.form.bookingLimit')"
                          :disabled="false"
                        />
                        <b-form-invalid-feedback v-if="validationContext.errors.length">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR input issueLimit -->
                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      :name="$t('packageConfig.form.issueLimit')"
                      vid="issueLimit"
                      rules="required"
                    >
                      <b-form-group label-for="issueLimit">
                        <template #label>
                          {{ $t('packageConfig.form.issueLimit') }}
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="issueLimit"
                          v-model="data.issueLimit"
                          v-remove-non-numeric-chars.allNumber
                          :name="'issueLimit'"
                          number
                          :state="getValidationState(validationContext)"
                          :placeholder="$t('packageConfig.form.issueLimit')"
                          :disabled="false"
                        />
                        <b-form-invalid-feedback v-if="validationContext.errors.length">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR input paxLimit -->
                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      :name="$t('packageConfig.form.paxLimit')"
                      vid="paxLimit"
                      rules="required"
                    >
                      <b-form-group label-for="paxLimit">
                        <template #label>
                          {{ $t('packageConfig.form.paxLimit') }}
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="paxLimit"
                          v-model="data.paxLimit"
                          v-remove-non-numeric-chars.allNumber
                          :name="'paxLimit'"
                          :state="getValidationState(validationContext)"
                          number
                          :placeholder="$t('packageConfig.form.paxLimit')"
                          :disabled="false"
                        />
                        <b-form-invalid-feedback v-if="validationContext.errors.length">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR input feeTransaction -->
                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      :name="$t('packageConfig.form.feeTransaction')"
                      vid="feeTransaction"
                      rules="required"
                    >
                      <b-form-group label-for="feeTransaction">
                        <template #label>
                          {{ $t('packageConfig.form.feeTransaction') }}
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="feeTransaction"
                          v-model="data.feeTransaction"
                          v-remove-non-numeric-chars.allNumber
                          :name="'feeTransaction'"
                          :state="getValidationState(validationContext)"
                          number
                          :placeholder="$t('packageConfig.form.feeTransaction')"
                          :disabled="false"
                        />
                        <b-form-invalid-feedback v-if="validationContext.errors.length">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR select exclusiveAgencies -->
                  <b-col md="6">
                    <b-form-group label-for="exclusiveAgencies">
                      <template #label>
                        {{ $t('packageConfig.form.exclusiveAgencies') }}
                        <span
                          v-if="true"
                          class="text-danger"
                        >*</span>
                      </template>
                      <v-select
                        id="exclusiveAgencies"
                        v-model="data.exclusiveAgencies"
                        taggable
                        :options="[]"
                        :placeholder="$t('packageConfig.form.exclusiveAgencies')"
                        multiple
                      >
                        <template #option="data">
                          <span>
                            {{ data.label }}
                          </span>
                        </template>
                        <template #selected-option="data">
                          <span>
                            {{ data.label }}
                          </span>
                        </template>
                        <template #no-options>
                          {{ $t('noOptions') }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <!-- switch -->
                  <b-col
                    cols="12"
                    offset="0"
                    order="end"
                  >
                    <b-row>
                      <!-- ANCHOR input enableQR -->
                      <b-col
                        cols="6"
                        md="3"
                      >
                        <b-form-group label-for="enableQR">
                          <template #label>
                            {{ $t('packageConfig.form.enableQR') }}
                          </template>
                          <!-- ANCHOR checkbox enableQR. -->
                          <b-form-checkbox
                            v-model="data.enableQR"
                            :value="data.enableQR"
                            :switch="true"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- ANCHOR input enableFilter -->
                      <b-col
                        cols="6"
                        md="3"
                      >
                        <b-form-group label-for="enableFilter">
                          <template #label>
                            {{ $t('packageConfig.form.enableFilter') }}
                          </template>
                          <!-- ANCHOR checkbox enableFilter. -->
                          <b-form-checkbox
                            v-model="data.enableFilter"
                            :value="data.enableFilter"
                            :switch="true"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- ANCHOR input isGift -->
                      <b-col
                        cols="6"
                        md="3"
                      >
                        <b-form-group label-for="isGift">
                          <template #label>
                            {{ $t('packageConfig.form.isGift') }}
                          </template>
                          <!-- ANCHOR checkbox isGift. -->
                          <b-form-checkbox
                            v-model="data.isGift"
                            :value="data.isGift"
                            :switch="true"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>

              <!-- ANCHOR descriptions -->
              <b-col md="6">
                <div class="p-0">
                  <div class="p-0 pb-1 d-flex justify-content-between align-items-center">
                    <label for="descriptions">{{ $t('packageConfig.form.descriptions') }}</label>

                    <!-- ANCHOR Button btnAddDescription. -->
                    <b-button
                      id="btnAddDescription"
                      v-b-tooltip.hover.v-primary.window="$t('packageConfig.btn.addDescription')"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary"
                      size="sm"
                      class="d-inline-flex align-items-center gap-2"
                      @click="()=>{
                        data.descriptions.push({
                          description: null,
                          value: false,
                          key: null,
                        })
                      }"
                    >
                      <span class="text-nowrap d-flex-center gap-1">
                        <IAmIcon
                          icon="plusCircleOutline"
                          size="18"
                        />
                        {{ $t('packageConfig.btn.addDescription') }}
                      </span>
                    </b-button>
                  </div>
                  <div
                    class="p-0"
                    style="max-height: 70vh; overflow-y: scroll;"
                  >
                    <b-row
                      v-for="(des,ind) in data.descriptions"
                      :key="ind"
                    >
                      <!-- ANCHOR input descriptionKey -->
                      <b-col md="4">
                        <b-form-group :label-for="`descriptionKey_${ind}`">
                          <template #label>
                            {{ $t('packageConfig.form.key') }}
                          </template>
                          <b-form-input
                            :id="`descriptionKey_${ind}`"
                            v-model="des.key"
                            style="font-family: monospace;"
                            :placeholder="$t('packageConfig.form.key')"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- ANCHOR input description -->
                      <b-col md="6">
                        <b-form-group :label-for="`description${ind}`">
                          <template #label>
                            {{ $t('packageConfig.form.description') }}
                          </template>
                          <b-form-input
                            :id="`description${ind}`"
                            v-model="des.description"
                            :placeholder="$t('packageConfig.form.description')"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- ANCHOR checkbox descriptionValue -->
                      <b-col md="1">
                        <b-form-group :label-for="`value_${ind}`">
                          <template #label>
                            {{ $t('packageConfig.form.value') }}
                          </template>
                          <div class="d-flex gap-1 justify-content-around">
                            <b-form-checkbox
                              :id="`value_${ind}`"
                              v-model="des.value"
                              class="py-50 px-75 rounded"
                            />
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- ANCHOR checkbox descriptionValue -->
                      <b-col md="1">
                        <b-form-group :label-for="`deleteDesciption_${ind}`">
                          <template #label>
                            {{ $t('action') }}
                          </template>

                          <!-- ANCHOR Button deleteDesciption. -->
                          <b-button
                            :id="`deleteDesciption_${ind}`"
                            v-b-tooltip.hover.v-danger.window="$t('delete')"
                            variant="flat-danger"
                            class="py-50 px-75 rounded"
                            size="sm"
                            :disabled="false"
                            @click="()=>{
                              data.descriptions.splice(ind, 1);
                            }"
                          >
                            <IAmIcon
                              icon="trash"
                              size="18"
                            />
                          </b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>

            <div class="pb-0">
              <div class="d-flex gap-2 justify-content-center">
                <!-- Back button -->
                <b-button
                  variant="secondary"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="()=>{$bvModal.hide('form-package-config-modal')}"
                >
                  {{ $t('back') }}
                </b-button>

                <!-- Next button -->
                <b-button
                  variant="info"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  :disabled="invalid"
                  type="submit"
                >
                  {{ $t('save') }}
                </b-button>
              </div>
            </div>
          </b-form>
        </IAmOverlay>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  BButton,
  BCol,
  BForm, BFormCheckbox, BFormGroup, BFormInput, BFormInvalidFeedback, BModal,
  BRow,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

import formValidation from '@/@core/comp-functions/forms/form-validation'
import { getDifference } from '@/@core/utils/utils'
import IAmInputMoney from '@/components/IAmInputMoney.vue'

export default {
  components: {
    BModal,
    BButton,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
    BFormInvalidFeedback,
    IAmInputMoney,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    vSelect,
  },
  props: {
    item: {
      type: Object,
      require: false,
      default: () => ({
        name: null,
        price: 0,
        unit: 'MONTH',
        searchLimit: 0,
        bookingLimit: 0,
        issueLimit: 0,
        paxLimit: 0,
        enableQR: true,
        enableFilter: true,
        feeTransaction: 0,
        isGift: true,
        type: '',
        exclusiveAgencies: [],
        descriptions: [
          {
            key: 'allowDomesticSearch',
            value: false,
            description: 'Tìm kiếm và đặt chỗ nội địa',
          },
          {
            key: 'allowFilterAndSort',
            value: false,
            description: 'Bộ lọc và sắp xếp chuyến bay',
          },
          {
            key: 'allowShowNearbyDate',
            value: false,
            description: 'Hiển thị ngày lân cận',
          },
          {
            key: 'allowManageVatInvoice',
            value: false,
            description: 'Quản lý thông tin yêu cầu xuất Hóa đơn VAT',
          },
          {
            key: 'allowManageServiceFee',
            value: false,
            description: 'Quản lý cầu hình phí dịch vụ/Phí xuất vé',
          },
          {
            key: 'allowSendEmail',
            value: false,
            description: 'Gửi email đơn hàng/Xác nhận thanh toán',
          },
          {
            key: 'allowProfessionalTicketing',
            value: false,
            description: 'Hệ thống thao tác vé chuyên nghiệp cho đại lý',
          },
          {
            key: 'allowManageBookingList',
            value: false,
            description: 'Quản lý danh sách vé đặt',
          },
          {
            key: 'allowManageReport',
            value: false,
            description: 'Quản lý báo cáo, công nợ',
          },
          {
            key: 'allowSearchBooking',
            value: false,
            description: 'Tra cứu chuyến bay/Đơn hàng đã đặt/giữ chỗ',
          },
          {
            key: 'allowBanUnbanIp',
            value: false,
            description: 'Ban/Unban IP Khách lẻ giao dịch bất thường',
          },
          {
            key: 'autoIssueTicket',
            value: false,
            description: 'Tự động xuất vé khi thanh toán',
          },
          {
            key: 'enableDraft',
            value: false,
            description: 'Hỗ trợ quản lý booking ảo',
          },
          {
            key: 'enableQR',
            value: false,
            description: 'Thanh toán QR',
          },
          {
            key: 'allowInternationalSearch',
            value: false,
            description: 'Tìm kiếm và đặt chỗ quốc tế',
          },
          {
            key: 'allowMultiSegmentsSearch',
            value: false,
            description: 'Tìm kiếm nhiều chặng',
          },
        ],
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // var
    const data = ref(cloneDeep(props.item))
    const units = ['MONTH', 'YEAR']
    // c
    watch(() => props.item, val => {
      data.value = cloneDeep(val)
    })

    // func
    const resetData = () => {
      data.value = cloneDeep(props.item)
    }

    function onShow() {
      resetData()
    }

    function onHide() {
      resetData()
    }

    function save() {
      data.value.descriptions = data.value.descriptions.filter(e => e.key !== null)
      emit('save', data.value.id ? getDifference(data.value, props.item) : data.value)
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData)

    return {
      units,
      data,
      refFormObserver,
      getValidationState,
      resetForm,

      onShow,
      onHide,
      save,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
