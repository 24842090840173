<template>
  <div>
    <b-alert
      show
      class="p-1"
    >
      {{ $t('customer.customer') }}
    </b-alert>
    <filter-customer
      :updatedCustomerInfo="updatedCustomerInfo"
      @filter-customer="handleFilteredCustomers"
      @filter-distric="handeleFilterDistrics"
      @toggle-add-customer="handleAddCustomer"
      @startDateFilter="filterStartDateBalance"
      @endDateFilter=" filterEndDateBalance"
      @filter-city="filterIdCity"
    />
    <b-overlay
      :show="loading"
      rounded="sm"
      no-fade
      variant="transparent"
      :opacity="0.5"
    >
      <b-table
        ref="refCustomerListTable"
        style="max-height: 100vh"
        :sticky-header="true"
        :items="updatedCustomerInfo"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        no-border-collapse
      >
        <template
          v-for="column in tableColumns"
          #[`head(${column.key})`]="data"
        >
          <span
            :key="column.label"
            class="text-dark text-nowrap"
          >
            {{ $t(`customer.${data.label}`) }}
          </span>
        </template>
        <template #cell(code)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize font-weight-bold">
              {{ data.item.code }}
            </span>
          </div>
        </template>

        <template #cell(fullName)="data">
          <b-media vertical-align="center">
            <div class="d-flex justify-content-between">
              <div class="d-flex  justify-content-start align-items-center gap-2">
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <b-button
                      class="btn-icon "
                      style="background: #D1ECF1 !important; border: #D1ECF1;"
                      pill
                    >
                      <feather-icon
                        icon="MoreHorizontalIcon"
                        style="color: #17A2B8 !important;"
                      />
                    </b-button>
                  </template>
                  <b-dropdown-item @click="showImportModalDetailCustomer(data.item)">
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="FileTextIcon"
                        size="14"
                        style="color: black !important;font-weight:600 ; align-items: center;"
                      />
                      {{ $t('customer.details') }}
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item @click="showImportModalEditHandle(data.item)">
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="Edit3Icon"
                        size="14"
                        style="color: black !important;font-weight:600 ; align-items: center;"
                      />
                      {{ $t('customer.edit') }}
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item @click="handleAddContact(data.item)">
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="UserPlusIcon"
                        size="14"
                        style="color: black !important;font-weight:600 ; align-items: center;"
                      />
                      {{ $t('customer.addCustomerDetails') }}
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
                <h5 class="mb-0">
                  {{ data.item.lastName }} {{ data.item.firstName }}
                </h5>
              <!-- </div> -->
              </div>
              <div
                v-if="!data.item.identifyNumber"
                class="d-flex-center"
              >
                <feather-icon
                  v-b-tooltip.hover.v-primary.window
                  title="Không có CCCD"
                  size="14"
                  icon="AlertCircleIcon"
                  class="mr-25 "
                  style="color: red;"
                />
              </div>
            </div>
          </b-media>
        </template>
        <template #cell(phoneNumber)="data">
          <small class="d-flex align-items-center">
            <span>{{ data.item.phoneNumber || $t('customer.noPhoneNumber') }}</span>
          </small>
        </template>
        <template #cell(emailAddress)="data">
          <small class="d-flex align-items-center">
            <feather-icon
              size="14"
              icon="MailIcon"
              class="mr-25"
            />
            <span class="">{{
              data.item.emailAddress
            }}
            </span>
          </small>
        </template>
        <template #cell(receivables)="data">
          <small class="d-flex align-items-center">

            <span class="text-info">{{
              data.item?.receivables?.toLocaleString()
            }}
            </span>
          </small>
        </template>
        <template #cell(profit)="data">
          <small class="d-flex align-items-center">

            <span class="text-info">{{
              formatCurrency(data.item.profit)
            }}
            </span>
          </small>
        </template>
        <template #cell(show_details)="row">
          <b-button
            v-if="row.item.contacts && row.item.contacts.length > 0"
            size="sm-1"
            class="rounded-circle p-1"
            style="background: #D1ECF1 !important; border: #D1ECF1;"

            variant="info"
            @click="handleButtonClick(row.item)"
          >
            <div v-if="row.item._showDetails">
              <feather-icon
                icon="ChevronUpIcon"
                style="color: #17A2B8 !important;"
              />
            </div>
            <div v-else>
              <feather-icon
                icon="ChevronDownIcon"
                style="color: #17A2B8 !important;"
              />
            </div>
          </b-button>
        </template>

        <template #row-details="row">
          <b-table
            style="max-height: 100%;"
            :sticky-header="true"
            class=" table_contact_class"
            :items="row.item.contacts"
            responsive
            :fields="tableColumnsContacts"
            primary-key="id"
            show-empty
            thead-class="d-none"
            empty-text="No matching records found"
            no-border-collapse
          >
            <template
              v-for="column in tableColumnsContacts"

              #[`head(${column.key})`]="data"
            >
              <span
                :key="column.label"

                class="text-dark  text-nowrap"
              >
                <!-- {{ $t(`customer.${data.label}`) }} -->
              </span>
            </template>
            <template #cell(code)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize font-weight-bold">
                  {{ data.item.code }}
                </span>
              </div>
            </template>
            <template #cell(fullName)="data">
              <div class="d-flex justify-content-between ">
                <div class="d-flex gap-2 justify-content-start align-items-center ">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                    boundary="window"
                  >
                    <template #button-content>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        style="background: #D1ECF1 !important; border: #D1ECF1;"

                        class="btn-icon"
                        pill
                      >
                        <feather-icon
                          icon="MoreHorizontalIcon"
                          style="color: #17A2B8 !important;"
                        />
                      </b-button>
                    </template>
                    <b-dropdown-item @click="handleViewContact(data.item)">
                      <div class="d-flex align-items-center">
                        <feather-icon
                          icon="FileTextIcon"
                          size="14"
                          style="color: black !important;font-weight:600 ; align-items: center;"
                        />
                        {{ $t('customer.details') }}
                      </div>
                    </b-dropdown-item>

                    <b-dropdown-item @click="handleEditContact(data.item)">
                      <div class="d-flex align-items-center">
                        <feather-icon
                          icon="Edit3Icon"
                          size="14"
                          style="color: black !important;font-weight:600 ; align-items: center;"
                        />
                        {{ $t('customer.edit') }}
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item @click="showImportModalDeleteHandle(row.item.id,data.item.id)">
                      <div class="d-flex align-items-center">
                        <feather-icon
                          icon="Trash2Icon"
                          size="14"
                          style="color: black !important;font-weight:600 ; align-items: center;"
                        />
                        {{ $t('customer.delete') }}
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <h5 class="mb-0">
                    {{ data.item.lastName }} {{ data.item.firstName }}
                  </h5>
                </div>
                <div
                  v-if="!data.item.identifyNumber"
                  class="d-flex-center"
                >
                  <feather-icon
                    v-b-tooltip.hover.v-primary.window
                    size="14"
                    icon="AlertCircleIcon"
                    title="Không có CCCD"
                    class="mr-25 "
                    style="color: red;"
                  />
                </div>
              </div>
            </template>
            <template #cell(phoneNumber)="data">
              <small class="d-flex align-items-center justify-content-start">

                <span>{{ data.item.phoneNumber || '' }}</span>
              </small>
            </template>
            <template #cell(emailAddress)="data">
              <small class="d-flex align-items-center justify-content-start">
                <span class="">{{
                  data.item.emailAddress || ''
                }}
                </span>
              </small>
            </template>
            <template #cell(receivables)="data">
              <small
                class="d-flex align-items-center"
                style="padding-left: 10%; width:50%;"
              >
                <!-- <span class="text-info">{{
                  formatCurrency(data.item.receivables)
                }}
                </span> -->

              </small>
            </template>
            <template #cell(profit)="data">
              <small
                class="d-flex align-items-center"
                style="padding-left: 10%; width:50%;"
              >

                <!-- <span class="text-info">{{
                  formatCurrency(data.item.profit)
                }}
                </span> -->
              </small>
            </template>
          </b-table>
        </template>
      </b-table>
    </b-overlay>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Showing -->
        <b-col
          cols="12"
          md="6"
          class=" justify-content-md-start"
        >
          <div class="d-flex align-items-center">
            <span class="text-muted d-flex">
              {{ $t('customer.view') }}
            </span>
            <b-form-group class="mb-0 form-group-custom width-200">
              <b-dropdown
                v-model="sizePerPage"
                variant="warning"
                :text="`${sizePerPage}`"
                class="per-page-selector d-inline-block p-0 mx-25"
              >
                <b-dropdown-item
                  v-for="item in sizePerPageLgOptions"
                  :key="item"
                  @click="sizePerPage = item"
                >
                  {{ item }}
                </b-dropdown-item>
              </b-dropdown>
            </b-form-group>
          </div>
          <span class="text-muted">
            {{ $t('customer.Customer') }}
            {{ dataMeta.from }}  {{ $t('paginationText.to') }} {{ dataMeta.to }} {{ $t('paginationText.outOf') }}
            {{ dataMeta.of }} {{ $t('paginationText.items') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          md="6"
          class="d-flex-center justify-content-md-end mt-1 mt-md-0 pr-lg-3"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalCustomers"
            :per-page="sizePerPage"
            first-number
            last-number
            class="pagination-warning mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <modal-edit-customer
      v-if="showImportModal"
      :mode="checkModalCustomer === false ? 'edit' : 'add'"
      :show-import-modal.sync="showImportModal"
      :editCustomerModal="checkModalCustomer === false ? editCustomerModal : {}"
      :readonlyModalDetail="readonly"
      @close-modal="handleCloseModal"
      @refetch-data="handleEditData"
      @back="onBack"
      @ok-detail="handleOkDetailCustomer"
    />
    <modal-edit-customer-detail
      v-if="showImportModalDetail"
      :mode="checkModalCustomer === false ? 'edit' : 'add'"
      :show-import-modal-detail.sync="showImportModalDetail"
      :editContactCustomer="checkModalCustomer === false ? editContactCustomer:{} "
      :editCustomerModal=" ContactId "
      :readonlyModalDetail="readonly"
      :addContactID="contactsInfo.id"
      @close-modal-detail="handleCloseDetailContact"
      @refetch-data="handleEditData"
      @back="onBack"
      @ok-detail-contact="handleOkDetailContact"
    />
  </div>
</template>

<script>
import {
  BAlert,
  BButton,
  BTable,
  BLink,
  BMedia,
  BAvatar,
  BDropdown,
  BOverlay,
  BDropdownItem,
  BFormGroup,
  BPagination,
  BRow,
  VBTooltip,
  BCol,

} from 'bootstrap-vue'
import {
  ref, computed, watch, nextTick, onMounted, getCurrentInstance,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import { apiCustomer } from '@/api'
import { sizePerPageLgOptions } from '@/constants/selectOptions'

import {
  avatarText, dateTime, formatCurrency, formatDateMonth,
} from '@core/utils/filter'

import useToast from '@useToast'

import ModalEditCustomerDetail from './ModalEditCustomerDetail.vue'
import ModalEditCustomer from './ModalEditCustomer.vue'
import FilterCustomer from './FilterCustomer.vue'

export default {
  components: {
    BAlert,
    BButton,
    vSelect,
    BTable,
    BLink,
    FilterCustomer,
    BMedia,
    BAvatar,
    BOverlay,
    BDropdown,
    BDropdownItem,
    ModalEditCustomer,
    ModalEditCustomerDetail,

    BPagination,
    BCol,
    BRow,
    BFormGroup,
    VBTooltip,
  },
  directives: {
    Ripple, 'b-tooltip': VBTooltip,
  },
  setup() {
    const searchTextFilter = ref('')
    const refCustomerListTable = ref(null)
    const { proxy: root } = getCurrentInstance()
    const customerInfo = ref([])
    const loading = ref(false)
    const checkModalCustomer = ref(false)

    const showImportModal = ref(false)
    const showImportModalDetail = ref(false)
    const editContactCustomer = ref({})
    const updatedCustomerInfo = ref([])
    const sizePerPageContacts = ref(40)
    const currentPageContacts = ref(1)
    const contactsInfo = ref([])
    const editCustomerModal = ref({})
    const updateCustomerData = ref()
    const ContactId = ref()
    const itemContactHandle = ref()
    const readonly = ref(false)
    const sizePerPage = ref(40)
    const filterCusDistrics = ref()
    const balanceStartDate = ref()
    const totalCustomers = ref(0)
    const currentPage = ref(1)
    const balanceEndDate = ref()
    const filterCusCity = ref()
    const dataMeta = computed(() => {
      const localItemsCount = refCustomerListTable.value
        ? refCustomerListTable.value.localItems.length
        : 0
      return {
        from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCustomers.value,
      }
    })

    const tableColumns = ref([
      { key: 'code', label: 'code', class: 'col-code' },
      { key: 'fullName', label: 'fullName', class: 'col-fullName' },
      { key: 'phoneNumber', label: 'phoneNumber', class: 'col-phoneNumber' },
      { key: 'emailAddress', label: 'emailAddress', class: 'col-emailAddress' },
      { key: 'receivables', label: 'receivables', class: 'col-receivables' },
      { key: 'profit', label: 'profit', class: 'col-profit' },
      { label: 'Action', key: 'show_details' },
    ])

    const { toastError, toastSuccess } = useToast()
    const showImportModalEditHandle = data => {
      showImportModal.value = true
      editCustomerModal.value = data
      checkModalCustomer.value = false
      readonly.value = false
    }
    const handleOkDetailCustomer = () => {
      showImportModal.value = false
    }
    const showImportModalDetailCustomer = data => {
      showImportModal.value = true
      editCustomerModal.value = data
      checkModalCustomer.value = false
      readonly.value = true
    }

    const handleEditContact = data => {
      // openContactModal(data, true)
      ContactId.value = itemContactHandle.value.id
      showImportModalDetail.value = true
      editContactCustomer.value = data
      checkModalCustomer.value = false
      readonly.value = false
    }

    const handleViewContact = data => {
      // openContactModal(data, false)
      showImportModalDetail.value = true
      editContactCustomer.value = data
      checkModalCustomer.value = false
      readonly.value = true
    }
    const handleAddContact = data => {
      ContactId.value = data.id
      showImportModalDetail.value = true
      editContactCustomer.value = {}
      checkModalCustomer.value = true
      readonly.value = false
    }
    const handleAddCustomer = () => {
      showImportModal.value = true
      editCustomerModal.value = {}
      checkModalCustomer.value = true
      readonly.value = false
    }
    const onBack = () => {
      if (showImportModal.value) {
        showImportModal.value = !showImportModal.value
      } else {
        showImportModalDetail.value = !showImportModalDetail.value
      }
    }
    const tableColumnsContacts = ref([
      { key: 'code', label: 'Code', class: 'col-code' },
      { key: 'fullName', label: 'Full Name', class: 'col-fullName' },
      { key: 'phoneNumber', label: 'Phone Number', class: 'col-phoneNumber' },
      { key: 'emailAddress', label: 'Email Address', class: 'col-emailAddress' },
      { key: 'receivables', label: 'Receivables', class: 'col-receivables' },
      { key: 'profit', label: 'Profit', class: 'col-profit' },
      { label: 'Action', key: 'show_details' },
    ])

    const fetchCustomers = async () => {
      loading.value = true
      try {
        const response = await apiCustomer.getAllCustomer({
          balanceStartDate: balanceStartDate.value,
          balanceEndDate: balanceEndDate.value,
          addressCityId: filterCusCity.value,
          addressDistrictId: filterCusDistrics.value,
          searchText: searchTextFilter.value || '',
          size: sizePerPage.value,
          page: currentPage.value,
        })
        customerInfo.value = response.data.items
        totalCustomers.value = response.data.total
        updatedCustomerInfo.value = customerInfo.value.map(customer => ({
          ...customer,
          _showDetails: false,
          backupPhoneNumbers: customer.backupPhoneNumbers
            ? customer.backupPhoneNumbers.split(' | ')
            : [],
        }))
        return response.data.items
      } catch (e) {
        console.error('Error fetching customers:', e)
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        throw e
      } finally {
        loading.value = false
      }
    }
    const refetchData = () => {
      refCustomerListTable.value.refresh()
      fetchCustomers()
    }

    const showImportModalDeleteHandle = async (contactsId, customerId) => {
      try {
        const titleMsg = root.$createElement('div', {
          domProps: { innerHTML: root.$t('modal.confirmDelete') },
        })

        const confirmed = await root.$bvModal.msgBoxConfirm(titleMsg, {
          title: root.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: root.$t('modal.yes'),
          cancelTitle: root.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })

        if (confirmed) {
          await apiCustomer.deleteContact(contactsId, customerId)
          toastSuccess({
            title: 'messagesList.success',
            content: 'Xóa thành công!',
          })
          refetchData()
        }
      } catch (e) {
        console.error('Error delete data', e)
      }
    }

    const handleCloseDetailContact = () => {
      showImportModalDetail.value = false
    }
    const handleOkDetailContact = () => {
      showImportModalDetail.value = false
    }
    const handleCloseModal = () => {
      showImportModal.value = false
    }
    const handleButtonClick = item => {
      // eslint-disable-next-line no-underscore-dangle
      item._showDetails = !item._showDetails
      itemContactHandle.value = item
    }
    const handleFilteredCustomers = filterCusData => {
      searchTextFilter.value = filterCusData
    }
    const filterIdCity = data => {
      filterCusCity.value = data
    }
    const handeleFilterDistrics = data => {
      filterCusDistrics.value = data
    }
    const handleEditData = editDataCustomer => {
      updateCustomerData.value = editDataCustomer
      refetchData()
    }
    const filterStartDateBalance = data => {
      balanceStartDate.value = data
    }
    const filterEndDateBalance = data => {
      balanceEndDate.value = data
    }
    watch([balanceStartDate, balanceEndDate], ([newStartDate, newEndDate], [oldStartDate, oldEndDate]) => {
      if (newStartDate !== oldStartDate || newEndDate !== oldEndDate) {
        refetchData()
      }
    })

    watch(
      [searchTextFilter, sizePerPage, currentPage, filterCusCity, filterCusDistrics],
      () => {
        refetchData()
      },
    )
    onMounted(() => {
      fetchCustomers()
    })
    return {
      handeleFilterDistrics,
      loading,
      refetchData,
      refCustomerListTable,
      avatarText,
      searchTextFilter,
      dataMeta,
      handleFilteredCustomers,
      handleViewContact,
      formatDateMonth,
      tableColumnsContacts,
      contactsInfo,
      updatedCustomerInfo,
      handleButtonClick,
      fetchCustomers,
      showImportModalDetailCustomer,
      customerInfo,
      tableColumns,
      dateTime,
      formatCurrency,
      showImportModalEditHandle,
      showImportModal,
      editCustomerModal,
      handleEditData,
      handleAddCustomer,
      checkModalCustomer,
      showImportModalDetail,
      handleEditContact,
      editContactCustomer,
      onBack,
      ContactId,
      filterStartDateBalance,
      filterIdCity,
      filterCusCity,
      handleAddContact,
      currentPage,
      totalCustomers,
      sizePerPage,
      filterEndDateBalance,
      handleCloseModal,
      readonly,
      handleCloseDetailContact,
      handleOkDetailCustomer,
      handleOkDetailContact,
      showImportModalDeleteHandle,
      sizePerPageLgOptions,

    }
  },
}
</script>

<style>
.col-code {
  width: 11%;
}
.col-fullName {
  width: 20%;
}
.col-phoneNumber {
  width: 15%;
}
.col-emailAddress {
  width: 20%;
}
.col-receivables {
  width: 15%;
}
.col-profit {
  width: 20%;
}
</style>
