<template>
  <validation-observer
    ref="refFormObserver"
    #default="{ invalid }"
  >
    <b-modal
      id="modal-customer-edit-contact-social-network"
      title="Mạng xã hội"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      lazy
      size="lg"
      no-close-on-backdrop
      @show="handleModalOpen"
      @hide="handleModalClose"
    >
      <template #modal-footer="{ close }">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mt-lg-25"
          variant="info"
          :disabled="invalid"
          pill
          @click="submitAddSocialNetwork"
        >
          <span class="align-middle">Xác nhận</span>
        </b-button>
      </template>

      <div>
        <b-form
          ref="form"
          @submit="submitAddSocialNetwork"
          @keyup.enter="submitAddSocialNetwork"
        >
          <b-row
            v-for="(item, index) in dataToAdd"
            :key="index"
            ref="row"
            class="mx-0"
            no-gutters
          >
            <b-col
              cols="12"
              md="4"
              class="pr-md-1"
            >
              <validation-provider
                #default="validationContext"
                name="Tên mạng xã hội"
                rules="required"
              >
                <b-form-group :label="`Mạng xã hội #${index + 1}`">
                  <b-form-input
                    :id="`number-${index}`"
                    v-model="item.type"
                    class="font-weight-bold text-dark font-medium-2"
                    :class="item.isDel ? 'border-danger text-decoration-line' : ''"
                    :disabled="item.isDel"
                    :state="getValidationState(validationContext) === false ? false : null"
                    placeholder="Nhập vào"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="7"
            >
              <validation-provider
                #default="validationContext"
                name="Địa chỉ"
                rules="url"
              >
                <b-form-group label="Địa chỉ (Url)">
                  <b-form-input
                    :id="`url-${index}`"
                    v-model="item.url"
                    :disabled="item.isDel"
                    class="font-weight-bold text-dark font-medium-2"
                    :class="item.isDel ? 'border-danger text-decoration-line' : ''"
                    :state="getValidationState(validationContext) === false ? false : null"
                    maxlength="30"
                    placeholder="Nhập vào"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="1"
              class="mb-50 d-flex align-items-end justify-content-end"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                :variant="item.isDel ? 'outline-info' : 'outline-danger'"
                :disabled="(dataToAdd.length === 1) && item.isNew"
                class="p-50 d-flex rounded-lg"
                style="margin-bottom: 7px;"
                @click="toggleItemDelete(index)"
              >
                <feather-icon
                  v-if="item.isDel"
                  icon="RotateCcwIcon"
                  size="20"
                />
                <feather-icon
                  v-else
                  icon="Trash2Icon"
                  size="20"
                />
              </b-button>
            </b-col>

            <b-col cols="12">
              <hr class="mt-0">
            </b-col>
          </b-row>
        </b-form>
      </div>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-primary"
        class="ml-75 py-25 rounded-pill d-flex-center"
        @click="handleAddNewItem"
      >
        <feather-icon
          icon="PlusIcon"
          size="25"
        />
      </b-button>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BModal, BFormGroup, BButton, BForm, BFormInput, BRow, BCol, BLink, BFormInvalidFeedback,
} from 'bootstrap-vue'
import {
  ref, reactive, watch, toRefs,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import cloneDeep from 'lodash/cloneDeep'

import { apiCustomer } from '@/api'

import formValidation from '@core/comp-functions/forms/form-validation'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BFormGroup,
    BButton,
    BForm,
    BFormInput,
    BRow,
    BCol,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    isDetailSocial: {
      type: Boolean,
      default: false,
    },
    socialAccounts: {
      type: [Array, null],
      default: () => null,
    },
  },
  setup(props, { emit, root }) {
    const { toastError, toastSuccess } = useToast()
    const blankData = reactive({
      url: '',
      type: '',
    })

    const dataToAdd = ref([])

    const resetDataToEdit = () => {
      dataToAdd.value = props.socialAccounts ? props.socialAccounts.map(account => ({
        ...account,

      })) : [cloneDeep(blankData)]
    }

    const { refFormObserver, getValidationState } = formValidation(resetDataToEdit)

    const handleAddNewItem = () => {
      dataToAdd.value.push(cloneDeep(blankData))
    }

    const toggleItemDelete = index => {
      const item = dataToAdd.value[index]
      if ((!item.type && !item.url) || item.isNew) {
        dataToAdd.value.splice(index, 1)
      } else {
        item.isDel = !item.isDel
      }
    }

    const submitAddSocialNetwork = async bvModalEvent => {
      bvModalEvent.preventDefault()

      const validSocialAccounts = dataToAdd.value.filter(item => !item.isDel && item.type && item.url)
      const socialDataContact = validSocialAccounts.map(({ type, url }) => ({ type, url }))

      const confirmed = await root.$bvModal.msgBoxConfirm('Xác nhận thay đổi?', {
        title: 'Xác nhận',
        size: 'sm',
        okVariant: 'info',
        cancelVariant: 'outline-secondary',
        centered: true,
      })

      if (confirmed) {
        emit('updateDetailSocialAccounts', socialDataContact)
        root.$bvModal.hide('modal-customer-edit-contact-social-network')
      }
    }

    const handleModalOpen = resetDataToEdit
    const handleModalClose = () => {}

    return {
      submitAddSocialNetwork,
      dataToAdd,
      handleModalOpen,
      handleModalClose,
      refFormObserver,
      handleAddNewItem,
      getValidationState,
      toggleItemDelete,
    }
  },
}
</script>
