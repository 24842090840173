export default {
  packageConfigsList: 'Package configs list',
  packageConfigsCreate: 'Create package config',
  agencyPackageConfigsList: 'Agency package configs list',
  agencyPackageConfigsCreate: 'Create',
  agencyPackageConfigsIncrease: 'Extend',
  agencyPackageConfigsBuy: 'Buy',
  agencyPackageConfigsEdit: 'Edit',
  agencyPackageConfigsGift: 'Gift',

  agencyPackageOrderList: 'Order list',
  agCreate: {
    title: 'Create',
  },
  columns: {
    name: 'Name',
    type: 'Type',
    price: 'Price',
    searchLimit: 'Search limit',
    specialNote: 'Note',
    bookingLimit: 'Booking limit',
    issueLimit: 'Issue limit',
    paxLimit: 'Pax limit',
    enableQR: 'Enable QR',
    unit: 'Unit',
    enableFilter: 'Enable filter',
    feeTransaction: 'Fee transaction',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    isActive: 'Status',
    agency: 'Agency',
    parentAgency: 'Agency F2',
    packageConfig: 'Package config',
    monthsUse: 'Months use',
    monthsExtra: 'Months extra',
    expiredAt: 'Expired at',
    enable: 'Enable',
    action: 'Action',
    paymentMethod: 'Payment method',
    payment: 'Payment',
    buyer: 'Buyer',
    agencyRetail: 'Agency retail',
    orderCode: 'Order code',
    totalAmountToBePaid: 'Total amount to be paid',
    totalPrice: 'Total price',
    searchCount: 'Search count',
    issueCount: 'Issue count',
    bookCount: 'Book count',
    periodStart: 'Start date',
    periodEnd: 'End date',
    percentage: 'Percentage (%)',
    amountMax: 'Amount max',
    code: 'Code',
    isUsed: 'Used',
    totalDiscount: 'Discount',
    autoIssueTicket: 'Auto issue ticket',
    enableDraft: 'Enable draft booking',
    domain: 'domain',
    isExpired: 'Expired',
    dateCreate: 'Date',
  },
  placeholder: {
    searchText: 'Search package config',
    agency: 'Agency',
  },
  buy: {
    title: 'Buy package config',
  },
  increase: {
    title: 'Extend package config',
  },
  payment: {
    title: 'Payment',
  },
  gift: {
    title: 'Gift package config',
  },
  CREDIT: 'Credit',
  MONEY: 'Money',
  PENDING: 'Pending',
  DONE: 'Done',
  CANCELED: 'Cancel',
  cancel: 'Cancel',
  coupon: {
    titleList: 'Package Coupons List',
    create: 'Create',
    createCoupon: 'Create coupon',
  },
  addDomain: 'Add domain',
  expired: 'Expired',
  unexpired: 'Unexpired',
  all: 'All',
  history: {
    title: 'History',
  },
  form: {
    create: 'Create package',
    update: 'Update package',
    name: 'Name',
    price: 'Price',
    unit: 'Unit',
    searchLimit: 'Search limit',
    bookingLimit: 'Booking limit',
    issueLimit: 'Issue limit',
    paxLimit: 'Pax limit',
    enableQR: 'Enable QR',
    enableFilter: 'Enable filter',
    feeTransaction: 'Transaction fee',
    isGift: 'Is gift',
    type: 'Type',
    exclusiveAgencies: 'Exclusive agencies',
    descriptions: 'Descriptions',
    placeholder: {
      name: 'Enter name',
      price: 'Enter price',
      unit: 'Enter unit',
      searchLimit: 'Enter search limit',
      bookingLimit: 'Enter booking limit',
      issueLimit: 'Enter issue limit',
      paxLimit: 'Enter pax limit',
      enableQR: 'Enable QR',
      enableFilter: 'Enable filter',
      feeTransaction: 'Enter transaction fee',
      isGift: 'Select if gift',
      type: 'Select if type',
      exclusiveAgencies: 'Enter exclusive agencies',
      descriptions: 'Enter descriptions',
    },
    key: 'Key',
    description: 'Description',
    value: 'Value',
  },
  units: {
    MONTH: 'Month',
    YEAR: 'Year',
  },
  btn: {
    addDescription: 'Add description',
  },
}
