<template>
  <div>
    <b-modal
      id="modal-modify-flight-search-trip"
      class="modal-modify-flight-search-trip"
      :title="$t('reservation.searchFlight')"
      centered
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="p-0"
      footer-class="d-flex justify-content-center"
      size="xl"
      no-close-on-backdrop
      @hide="handleCloseModalSearch"
      @show="showHandle"
    >
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        spinner-variant="primary"
        opacity="0.9"
      >
        <div
          class="d-flex-center"
          :style="{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${backgroundFlightSearchUrl})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'relative',
            minHeight: '80vh'
          }"
        >
          <div
            ref="refModalModifySearch"
            class="search-flight-modal d-flex flex-column justify-content-start align-items-center"
          >
            <div class="w-100 d-flex-between">
              <SearchSwitchTypeCheckbox
                :type.sync="searchData.type"
                :enable-round-trip="enableSearchRT"
              />
            </div>

            <oneWay
              v-if="searchData.type === 'OW'"
              :search-data.sync="searchData"
              :booking-data="getBookingData"
              :is-add-flights="isAddFlights"
              @swap-from-to="swapHandle"
            />
            <roundTrip
              v-if="searchData.type === 'RT'"
              :search-data.sync="searchData"
              :booking-data="getBookingData"
              :is-add-flights="isAddFlights"
              @swap-from-to="swapHandle"
            />
          </div>
        </div>
      </b-overlay>

      <template #modal-footer="{ close }">
        <b-button
          variant="outline-secondary"
          class="center rounded-pill mr-md-2"
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient border-0 px-1 px-md-2"
          pill
          :disabled="!isActiveSearchButton"
          @click="handleSearchButton"
        >
          <div class="d-flex-center px-25">
            <b-img
              v-if="!isMobileView"
              src="@icons/search.svg"
              alt="Search"
              width="20"
              class="mr-25 ico-bold"
            />
            {{ $t('reservation.searchFlight') }}
          </div>
        </b-button>
      </template>
    </b-modal>

    <ChangeFlightsResult :booking-data="getBookingData" />
  </div>
</template>

<script>
import {
  BModal, BButton, BOverlay, BImg,
} from 'bootstrap-vue'
import {
  ref,
  watch,
  computed,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment/moment'

import store from '@/store'
import { defaultDeparture, defaultArrival } from '@/constants/flight'
import router from '@/router'
import { isDomesticFlight } from '@/constants/selectOptions'
import env from '@/libs/env'

import { convertISODateTime } from '@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'
import useReservationDetailBookingHandle from '@reservation/reservation-modify/components/detail/flights-details/useReservationDetailBookingHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BOverlay,
    BImg,

    oneWay: () => import('./one-way.vue'),
    roundTrip: () => import('./round-trip.vue'),

    ChangeFlightsResult: () => import('../change-flights/result/index.vue'),
    SearchSwitchTypeCheckbox: () => import('./components/SearchSwitchTypeCheckbox.vue'),

  },
  setup() {
    const { backgroundFlightSearchUrl } = env
    const { toastError } = useToast()
    const { getBookingData, resetSearchStore } = useReservationHandle()
    const {
      fetchAirportGroup,
      fetchAirports,
      getAirportGroup,
      isAddFlights,
      searchFlightForModify,
      itinerariesByIndexSelected,
    } = useReservationDetailBookingHandle()

    const enableSearchRT = computed(() => {
      if (!isEmpty(itinerariesByIndexSelected.value) && itinerariesByIndexSelected.value.length === 2) {
        if (getBookingData.value.source.includes('VN1A')) return true
      }
      return false
    })

    const blankSearchData = {
      adult: 1,
      child: 0,
      infant: 0,
      type: 'OW',
      currency: 'VND',
      numberOfStop: 0,
      flights: [
        {
          startPoint: defaultDeparture,
          endPoint: defaultArrival,
          departDate: moment().add(1, 'days').format('YYYY-MM-DD'), // Ngày khởi hành
          returnDate: moment().add(3, 'days').format('YYYY-MM-DD'), // Ngày về
        },
      ],
      airlines: [],
      promoCodes: [],
      isSearchClassBooking: false,
      isSearchMonthlyCheapestFare: false,
    }

    const searchData = ref(cloneDeep(blankSearchData))
    const loading = ref(false)
    const isActiveSearchButton = ref(false)
    const backupData = ref(null)

    watch(itinerariesByIndexSelected.value, val => {
      if (!isEmpty(val) && val.length === 2) {
        if (getBookingData.value.source.includes('VN1A')) {
          searchData.value.type = 'RT'
        }
      }
    }, { deep: true, immediate: true })

    if (isEmpty(getAirportGroup.value)) {
      fetchAirportGroup()
    }

    if (!searchData.value.flights[0].startPoint) {
      searchData.value.flights[0].startPoint = defaultDeparture
    }

    if (!searchData.value.flights[0].endPoint) {
      searchData.value.flights[0].endPoint = defaultArrival
    }

    function getTransPoint(trip) {
      if (trip.length > 1) {
        return {
          startPoint: trip[0].departure.iataCode,
          endPoint: trip[trip.length - 1].arrival.iataCode,
        }
      }
      return {
        startPoint: trip[0].departure.iataCode,
        endPoint: trip[0].arrival.iataCode,
      }
    }

    function getDataPax(bookingData, type) {
      const source = bookingData.source

      if (['VN1A', 'VN1A_MT'].includes(source)) {
        // ANCHOR VN1A đổi vé ĐÃ THANH TOÁN thì lấy những pax có số vé OPEN; đổi vé chưa thanh toán trả về số paxByPaxType length
        const paxs = bookingData.paxLists.filter(p => p.paxType.includes(type) && (['HOLD'].includes(bookingData.status) ? true : !isEmpty(p.eticket?.filter(e => ['TKT'].includes(e.ticketType) && ['OPEN'].includes(e.status)))))
        return paxs?.length || 0
      }
      const paxs = bookingData.paxLists.filter(p => p.paxType === type)
      return paxs?.length || 0
    }

    const showHandle = async () => {
      loading.value = true

      try {
        const dataTrip = getBookingData.value
        const airlines = [dataTrip.source]
        const addFlight = await isAddFlights.value

        let startPoint
        let endPoint
        let departDate
        let type = 'OW'

        if (!addFlight) {
          startPoint = await fetchAirports(getTransPoint(itinerariesByIndexSelected.value[0]).startPoint).then(res => res.data.items[0])
          endPoint = await fetchAirports(getTransPoint(itinerariesByIndexSelected.value[0]).endPoint).then(res => res.data.items[0])
          departDate = convertISODateTime(itinerariesByIndexSelected.value[0][0].departure.at, itinerariesByIndexSelected.value[0][0].departure.timeZone).dateFilter
          type = (itinerariesByIndexSelected.value.length > 1) ? 'RT' : 'OW'
        } else if (addFlight && !isEmpty(dataTrip.itineraries)) {
          const fSegment = dataTrip.itineraries[0][0]

          if (fSegment) {
            startPoint = await fetchAirports(fSegment.departure.iataCode).then(res => res.data.items[0])
            endPoint = await fetchAirports(fSegment.arrival.iataCode).then(res => res.data.items[0])
            departDate = convertISODateTime(fSegment.departure.at, fSegment.departure.timeZone).dateFilter
          }
        }

        searchData.value = {
          ...searchData.value,
          type,
          adult: getDataPax(dataTrip, 'ADULT'),
          child: getDataPax(dataTrip, 'CHILD'),
          infant: getDataPax(dataTrip, 'INFANT'),
          airlines,
          flights: [
            {
              ...searchData.value.flights[0],
              startPoint: startPoint || defaultDeparture,
              endPoint: endPoint || defaultArrival,
              ...(departDate && { departDate }),
            },
          ],
        }

        if (addFlight && dataTrip.source.includes('VN1A')) {
          searchData.value.isSearchClassBooking = true
        }
      } catch (error) {
        console.error(error)
      } finally {
        loading.value = false
      }
    }

    function swapHandle(index = 0) {
      const temp = searchData.value.flights[index].startPoint
      searchData.value.flights[index].startPoint = searchData.value.flights[index].endPoint
      searchData.value.flights[index].endPoint = temp
    }

    watch(
      () => searchData.value.isSearchClassBooking,
      val => {
        if (val) {
          backupData.value = {
            numberOfStop: searchData.value.numberOfStop,
          }
          searchData.value.numberOfStop = 0
        } else if (backupData.value && !val) {
          searchData.value.numberOfStop = backupData.value.numberOfStop || 99
        }
      },
    )

    watch(
      () => searchData.value.flights,
      val => {
        if (val) {
          isActiveSearchButton.value = val.every(item => {
            const sPoint = item?.startPoint?.iata || item?.startPoint
            const ePoint = item?.endPoint?.iata || item?.endPoint
            if (sPoint === ePoint) {
              toastError({
                title:
                  'messagesList["Departure and destination must be difference"]',
              })
              return false
            }
            return true
          })

          const isDomestic = val.every(item => isDomesticFlight(item.startPoint?.iata || item.startPoint, item.endPoint?.iata || item.endPoint))
          const hasVCS = val.some(item => [item.startPoint?.iata || item.startPoint, item.endPoint?.iata || item.endPoint].includes('VCS'))

          if ((!isDomestic && searchData.value.numberOfStop !== 99) || hasVCS) {
            searchData.value.numberOfStop = 99
          } else if (isDomestic && searchData.value.numberOfStop !== 0) {
            searchData.value.numberOfStop = 0
          }
        }
      },
      { deep: true },
    )

    function modifySearchData(searchData) {
      if (searchData.type === 'OW' || searchData.type === 'RT') {
        searchData.flights.splice(1)
      }

      const payload = cloneDeep(searchData)

      payload.flights = payload.flights.map(item => ({
        ...item,
        startPoint: item.startPoint.iata,
        endPoint: item.endPoint.iata,
      }))

      return payload
    }

    function modifySearchClassBooking(searchData) {
      if (searchData.type === 'OW' || searchData.type === 'RT') {
        searchData.flights.splice(1)
      }

      const payload = {
        airline: 'VN1A',
        airlines: ['VN1A'],
        adult: searchData.adult,
        child: searchData.child,
        infant: searchData.infant,
        type: searchData.type, // : 'OW', // searchData.type,
        isSearchMonthlyCheapestFare: searchData.isSearchMonthlyCheapestFare,
        isSearchClassBooking: searchData.isSearchClassBooking,
        flights: [
          {
            startPoint: searchData.flights[0].startPoint.iata,
            endPoint: searchData.flights[0].endPoint.iata,
            departDate: convertISODateTime(searchData.flights[0].departDate).dateFilter,
            ...(['RT'].includes(searchData.type) && {
              returnDate: convertISODateTime(searchData.flights[0].returnDate).dateFilter,
            }),
          },
        ],
      }

      return payload
    }

    const classBookingResult = ref(null)

    const requestConfig = {
      SEARCH_V2_ADD: {
        requestType: 'SEARCH_V2',
        session: 'ADD_FLIGHT',
      },
      SEARCH_CLASS_BOOKING_ADD: {
        requestType: 'SEARCH_CLASS_BOOKING',
        session: 'ADD_FLIGHT',
      },
      SEARCH_FOR_MODIFY_ADD: {
        requestType: 'SEARCH_FOR_MODIFY',
        session: 'ADD_FLIGHT',
      },
      SEARCH_FOR_MODIFY_CHANGE: {
        requestType: 'SEARCH_FOR_MODIFY',
        session: 'CHANGE_FLIGHT',
      },
    }

    async function handleSearchButton() {
      store.dispatch('app/setLoading', true)

      try {
        const dataTrip = getBookingData.value

        if (getBookingData.value.source.includes('VN1A')) {
          if (searchData.value.isSearchClassBooking) {
            localStorage.setItem(
              'search-for-modify',
              JSON.stringify(modifySearchClassBooking(searchData.value)),
            )
            localStorage.setItem('bookingData', JSON.stringify(dataTrip)) // lưu bookingData vào local để sử dụng

            router.push({
              name: 'apps-reservations-modify-add-flights-class-booking',
            })
          } else {
            localStorage.setItem(
              'search-for-modify',
              JSON.stringify(modifySearchData(searchData.value)),
            )

            if (isAddFlights.value) {
              localStorage.setItem(
                'searchSession',
                JSON.stringify(requestConfig.SEARCH_V2_ADD),
              )
              localStorage.setItem('bookingData', JSON.stringify(dataTrip)) // lưu bookingData vào local để sử dụng

              router.push({
                name: 'apps-reservations-modify-add-flights',
              })
            } else {
              const payloadSearchForModify = {
                ...searchData.value,
                pnrNumber: dataTrip.bookingCode,
                oldItineraryId: itinerariesByIndexSelected.value[0][0].itineraryId ?? itinerariesByIndexSelected.value[0][0].segmentId,
              }

              payloadSearchForModify.flights = searchData.value.flights.map(
                item => ({
                  ...item,
                  startPoint: item.startPoint.iata,
                  endPoint: item.endPoint.iata,
                }),
              )

              await searchFlightForModify(payloadSearchForModify)

              this.$bvModal.show('modal-modify-change-flight-result')
            }
          }
        } else if (['VJ'].includes(getBookingData.value.source)) {
          const payloadSearchForModify = {
            ...searchData.value,
            pnrNumber: dataTrip.bookingCode,
            oldItineraryId: isAddFlights.value
              ? ''
              : itinerariesByIndexSelected.value[0][0].itineraryId
                ?? itinerariesByIndexSelected.value[0][0].segmentId,
          }

          localStorage.setItem(
            'search-for-modify',
            JSON.stringify(modifySearchData(payloadSearchForModify)),
          )

          if (isAddFlights.value) {
            localStorage.setItem(
              'searchSession',
              JSON.stringify(requestConfig.SEARCH_FOR_MODIFY_ADD),
            )
            localStorage.setItem('bookingData', JSON.stringify(dataTrip)) // lưu bookingData vào local

            router.push({
              name: 'apps-reservations-modify-add-flights',
            })
          } else {
            const payloadSearchForModify = {
              ...searchData.value,
              pnrNumber: dataTrip.bookingCode,
              oldItineraryId: isAddFlights.value
                ? ''
                : itinerariesByIndexSelected.value[0][0].itineraryId
                  ?? itinerariesByIndexSelected.value[0][0].segmentId,
            }

            payloadSearchForModify.flights = searchData.value.flights.map(
              item => ({
                ...item,
                startPoint: item.startPoint.iata,
                endPoint: item.endPoint.iata,
              }),
            )

            await searchFlightForModify(payloadSearchForModify)

            this.$bvModal.show('modal-modify-change-flight-result')
          }
        } else {
          const payloadSearchForModify = {
            ...searchData.value,
            pnrNumber: dataTrip.bookingCode,
            oldItineraryId: isAddFlights.value
              ? ''
              : itinerariesByIndexSelected.value[0][0].itineraryId
                ?? itinerariesByIndexSelected.value[0][0].segmentId,
          }

          payloadSearchForModify.flights = searchData.value.flights.map(
            item => ({
              ...item,
              startPoint: item.startPoint.iata,
              endPoint: item.endPoint.iata,
            }),
          )

          await searchFlightForModify(payloadSearchForModify)

          if (isAddFlights.value) {
            this.$bvModal.show('modal-modify-add-flight-result')
          } else {
            this.$bvModal.show('modal-modify-change-flight-result')
          }
        }
      } catch (error) {
        console.warn(error)
      } finally {
        store.dispatch('app/setLoading', false)
      }
    }

    function handleCloseModalSearch() {
      resetSearchStore()
      searchData.value = cloneDeep(blankSearchData)
      store.dispatch('app-reservation/setIsAddFlights', false)
      this.$bvModal.hide('modal-modify-flight-search-trip')
    }

    return {
      loading,
      isAddFlights,
      getBookingData,
      classBookingResult,
      backgroundFlightSearchUrl,
      showHandle,
      searchData,
      swapHandle,
      modifySearchData,
      handleSearchButton,
      handleCloseModalSearch,
      isActiveSearchButton,

      enableSearchRT,
    }
  },
}
</script>

<style lang="scss">
.modal-modify-flight-search-trip.modal-body ::v-deep {
  padding: 0 !important;
}

.search-flight-modal {
  background: rgba(154, 154, 154, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  width: 1036px;
  max-width: calc(100% - 24px);
  margin: 10px 0;
  padding: 30px;
}

@media (max-width: 768px) {
  .search-flight-modal {
    padding: 12px;
  }
}

.card {
  border-radius: 8px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
