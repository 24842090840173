<template>
  <!-- SECTION modal-special-services -->
  <b-modal
    id="modal-special-services"
    :title="$t('reservation.specialServices.title')"
    title-class="text-airline font-medium-4 font-weight-bolder mb-0"
    centered
    no-close-on-backdrop
    size="lg"
    @show="handleShowModal"
  >
    <IAmOverlay
      :loading="loading"
      :spinner-variant="'info'"
    >
      <!-- ANCHOR Itineraries -->
      <div class="text-heading-5 mb-25 fw-700">
        {{ $t('reservation.specialServices.itineraries.title') }}
      </div>
      <b-form-group class="ml-50 mb-0">
        <template #label>
          <b-form-checkbox
            v-model="itinerariesAllSelected"
            aria-describedby="flights"
            aria-controls="flights"
            class="custom-checkbox-label"
            @change="itinerariesToggleAll"
          >
            <div class="text-body-2">
              {{ itinerariesAllSelected ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
            </div>
          </b-form-checkbox>
        </template>
        <template #default="{ ariaDescribedby }">
          <b-form-checkbox-group
            id="checkbox-group-cancel-flight-vn1a"
            v-model="itinerariesSelected"
            :aria-describedby="ariaDescribedby"
            stacked
            class="ml-50 ml-sm-1"
            name="flights"
          >
            <div
              v-for="(segment) in segmentList"
              :key="segment.index"
              class="d-flex "
            >
              <b-form-checkbox
                :value="segment"
                class="custom-checkbox-label mb-50"
              >
                <span class="text-heading-4">{{ segment.segmentText }}</span>
              </b-form-checkbox>
            </div>
          </b-form-checkbox-group>
        </template>
      </b-form-group>

      <!-- ANCHOR Passengers -->
      <div class="text-heading-5 mb-25 fw-700">
        {{ $t('reservation.specialServices.passengers.title') }}
      </div>
      <b-form-group class="ml-50 mb-0">
        <template #label>
          <b-form-checkbox
            v-if="bookingData.paxLists.length > 1"
            v-model="passengersAllSelected"
            aria-describedby="flights"
            aria-controls="flights"
            @change="passengersToggleAll"
          >
            <div class="text-body-2">
              {{ passengersAllSelected ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
            </div>
          </b-form-checkbox>
        </template>

        <template #default="{ ariaDescribedby }">
          <b-form-checkbox-group
            v-model="passengersSelected"
            :aria-describedby="ariaDescribedby"
            stacked
            name="passengers"
            class="ml-50 ml-sm-1 mb-1"
          >
            <div
              v-for="(passenger, indexPassenger) in bookingData.paxLists"
              :key="indexPassenger"
              class="d-flex flex-column my-25"
            >
              <b-form-checkbox :value="passenger.paxId">
                <div class="d-flex align-items-center">
                  <div class="mr-50 text-heading-4">
                    {{ `${passenger.paxId} ${passenger.lastName} ${passenger.firstName} ${passenger.title}` }}
                  </div>
                </div>
              </b-form-checkbox>
            </div>
          </b-form-checkbox-group>
        </template>
      </b-form-group>

      <!-- ANCHOR Special services -->
      <div class="text-heading-5 mb-25 fw-700">
        {{ $t('reservation.specialServices.ss.title') }}
      </div>

      <v-select
        v-model="specialServiceSelected"
        :options="specialServicesListVN1A"
        :reduce="val => val"
        :selectable="(option) => !option.isGroup"
        class="ml-50"
      >
        <!-- @input="handleChangeInput" -->
        <template #selected-option="data">
          <div class="d-flex align-items-center">
            <div class="text-info fw-700">
              {{ data.value }}
            </div>
            <div class="ml-1 text-wrap">
              {{ data.label }}
            </div>
          </div>
        </template>
        <template #option="data">
          <div :class="`d-flex ${data.isGroup ? 'mt-1' : ''}`">
            <div :class="`fw-700 ${specialServiceSelected && data.value === specialServiceSelected.value ? 'text-white' : 'text-info'}`">
              {{ data.value }}
            </div>
            <div :class="`${data.value ? 'ml-1' : ''} text-wrap`">
              {{ data.label }}
            </div>
          </div>
        </template>
        <template #no-options>
          {{ $t('flight.noOptions') }}
        </template>
      </v-select>

      <IAmConfirm
        v-if="!isDisabledSubmit"
        :is-confirm.sync="isConfirm"
      />
    </IAmOverlay>

    <template #modal-footer="{close}">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill width-100"
        @click="close()"
      >
        {{ $t('reservation.close') }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient mt-lg-25  border-0"
        :disabled="!isConfirm || isDisabledSubmit"
        pill
        @click="confirmAddSsrsHandle"
      >
        <span class="align-middle">{{ $t('reservation.specialServices.book') }}</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import {
  ref, toRefs, watch, computed,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import IAmConfirm from '@/components/IAmConfirm.vue'
import { convertShortTrip } from '@/@core/utils/filter'
import { specialServicesListVN1A } from '@/constants/selectOptions'
import IAmOverlay from '@/components/IAmOverlay.vue'

import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    IAmConfirm,

    vSelect,
    IAmOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    bookingData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { commandsGetBooking, commandAddSsrs, loading } = useReservationHandle()
    const bookingData = toRefs(props).bookingData

    const specialServiceSelected = ref()
    const itinerariesSelected = ref([])
    const passengersSelected = ref([])
    const itinerariesAllSelected = ref(false)
    const passengersAllSelected = ref(false)
    const segmentList = ref([])

    async function getSegment() {
      const payload = {
        source: bookingData.value.source,
        pnrNumber: bookingData.value.bookingCode,
      }
      const res = await commandsGetBooking(payload)
      segmentList.value = res?.segmentList ?? []
    }

    async function handleShowModal() {
      itinerariesSelected.value = []
      passengersSelected.value = []
      itinerariesAllSelected.value = false
      passengersAllSelected.value = false
      specialServiceSelected.value = null
      segmentList.value = []

      await getSegment()
    }

    function itinerariesToggleAll(checked) {
      itinerariesSelected.value = checked ? segmentList.value.slice() : []
    }
    watch(itinerariesSelected, newValue => {
      if (newValue.length === 0) {
        itinerariesAllSelected.value = false
      } else if (newValue.length === segmentList.value.length) {
        itinerariesAllSelected.value = true
      } else {
        itinerariesAllSelected.value = false
      }
    })

    function passengersToggleAll(checked) {
      passengersSelected.value = checked
        ? bookingData.value.paxLists.map(item => item.paxId).slice()
        : []
    }
    watch(passengersSelected, newValue => {
      if (newValue.length === 0) {
        passengersAllSelected.value = false
      } else if (newValue.length === bookingData.value.paxLists.map(item => item.paxId).length) {
        passengersAllSelected.value = true
      } else {
        passengersAllSelected.value = false
      }
    })

    async function confirmAddSsrsHandle() {
      const payload = {
        source: bookingData.value.source,
        agencyCode: bookingData.value.agency,
        paxCode: bookingData.value.paxContact.code,
        pnrNumber: bookingData.value.bookingCode,
        paxSsrs: itinerariesSelected.value.flatMap(({ segmentText }) => passengersSelected.value.map(paxId => ({
          paxId,
          segmentText,
          ssrCode: specialServiceSelected.value.value,
        }))),
      }

      console.log({
        payload,
        itinerariesSelected: itinerariesSelected.value,
        specialServiceSelected: specialServiceSelected.value,
        passengersSelected: passengersSelected.value,
      })

      this.$bvModal.show('modal-api-loading')
      try {
        await commandAddSsrs(payload)
        this.$bvModal.hide('modal-special-services')
      } catch (err) {
        console.error('Error add ssrs: ', err)
      } finally {
        this.$bvModal.hide('modal-api-loading')
      }
    }

    const isConfirm = ref(false)
    const isDisabledSubmit = computed(() => !specialServiceSelected.value || !itinerariesSelected.value.length || !passengersSelected.value.length)

    return {
      loading,
      specialServicesListVN1A,
      specialServiceSelected,
      itinerariesSelected,
      handleShowModal,
      itinerariesToggleAll,
      itinerariesAllSelected,
      convertShortTrip,
      passengersSelected,
      passengersAllSelected,
      passengersToggleAll,
      isConfirm,
      isDisabledSubmit,
      removeAccentsUpperCaseFormatter,
      confirmAddSsrsHandle,
      segmentList,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
