<template>
  <div>
    <b-col>
      <b-row
        class="justify-content-center mb-1"
        style="gap:3%;"
      >
        <b-card style="background: #f3f3f3; width: 360px;">
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between "
                :label="`${$t('customer.lastName')} ${isEditActive} `"
                label-for="lastName"
              >
                <validation-provider
                  #default="validationContext"
                  name="Last Name"
                  style="width: 100%;"
                  rules="required"
                  mode="eager"
                >
                  <div v-if="readonlys === false">
                    <b-form-input
                      id="lastName"
                      v-model="customerDataInfo.lastName"
                      style="max-width: 190px"
                      :disabled="readonlys"
                      :state="
                        getValidationState(validationContext) === false
                          ? false
                          : null
                      "
                      maxlength="30"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('customer.lastName')"
                    />
                  </div>
                  <div v-else>
                    <span>{{ customerDataInfo.lastName || '-' }}</span>
                  </div>

                  <b-form-invalid-feedback
                    style="max-width: 190px"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-row>
          </b-col>

          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between "
                :label="`${$t('customer.firstName')} ${isEditActive}`"
                label-for="firstName"
              >
                <validation-provider
                  #default="validationContext"
                  name="First Name"
                  style="width: 100%;"
                  rules="required"
                  mode="eager"
                >
                  <div v-if="readonlys === false">
                    <b-form-input
                      id="firstName"
                      v-model="customerDataInfo.firstName"
                      style="max-width: 190px"
                      :disabled="readonlys"
                      :state="
                        getValidationState(validationContext) === false
                          ? false
                          : null
                      "
                      maxlength="30"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('customer.firstName')"
                    />
                  </div>
                  <div v-else>
                    <span>{{ customerDataInfo.firstName || '-' }}</span>
                  </div>

                  <b-form-invalid-feedback
                    style="max-width: 190px"
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <!-- <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between "
                :label="`${$t('customer.passengerType')} ${isEditActive}`"
                label-for="type"
              >
                <validation-provider
                  #default="validationContext"
                  name="Type"
                  style="width: 100%;"
                  mode="eager"
                >
                  <div v-if="readonlys === false">
                    <v-select
                      v-model="customerDataInfo.type"
                      label="label"
                      class="fixed-width-select"
                      style="  max-width: 190px; background: #ffff;border-radius: 10px;"
                      :placeholder="$t('customer.typeOfPassenger')"
                      :options="typeOptons"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      :disabled="readonlys"
                      input-id="type"
                    >
                      <template #option="{label}">
                        <span>
                          {{ $t(`customer.typePassPeople.${label}`) }}
                        </span>
                      </template>

                      <template #selected-option="{label}">
                        <span>
                          {{ $t(`customer.typePassPeople.${label}`) }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('customer.noOptions') }}
                      </template>
                    </v-select>
                  </div>
                  <div v-else>
                    <span>{{ customerDataInfo.type || '-' }}</span>
                  </div>

                  <b-form-invalid-feedback
                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-row> -->
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                :label="`${$t('customer.createdAt')} `"
                style="width: 100%;"
                label-for="createdAt"
              >
                <div v-if="readonlys === false">
                  <flat-pickr
                    id="createdAt"
                    v-model="customerDataInfo.createdAt"
                    :disabled="true"
                    class="form-control mb-0 bg-white"
                    :placeholder="$t('customer.createdAt')"
                    :config="{
                      allowInput: true,
                      dateFormat: 'Y-m-d',
                      altFormat: 'd-m-Y',
                      altInput: true,
                      disableMobile: true,
                    }"
                  />
                </div>

                <div v-else>
                  <span>{{ getDate(customerDataInfo.createdAt) || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>
          <b-row>
            <b-col>
              <div class="d-flex align-items-center justify-content-between pb-2">
                <h6 class="text-body m-0">
                  Mạng xã hội
                </h6>

                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.v-primary.window

                  title="Chỉnh sửa"
                  size="sm"

                  variant="outline-info"
                  class="btn-icon py-25 px-50 d-flex-center"
                  @click="editSocialNetwork(customerDataInfo)"
                >
                  <img src="@icons/edit.svg">
                  <span
                    v-if="!isMobileView"
                    class="font-medium-2 ml-25 "
                    style="margin-top: 1px; display: flex; justify-content: center;"
                  >
                    Thay đổi
                  </span>
                </b-button>
              </div>

              <div v-if="customerDataInfo.socialAccounts">
                <div
                  v-for="(item, i) in customerDataInfo.socialAccounts"
                  :key="i"
                  class="mb-50 d-flex align-items-center"
                >
                  <span
                    class="mr-50 font-weight-bolder"
                    style="min-width: 120px"
                  >
                    {{ item.type }}
                  </span>

                  <b-link
                    class="url-ellipsis"
                    :href="item.url"
                    target="_blank"
                  >
                    {{ item.url }}
                  </b-link>
                </div>
              </div>

              <div v-else>
                Chưa thêm mạng xã hội
              </div>
              <ModalEditContactSocialNetwork
                v-if="customerDataInfo"
                :social-accounts="customerDataInfo.socialAccounts"
                @updateDetailSocialAccounts="handleDetailSocialAccountsUpdate"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-card
          class="mb-1"
          style="background: #f3f3f3; width: 360px;"
        >
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.phoneNumber')}`"
                label-for="phone-number"
              >
                <div
                  v-if="readonlys === false"
                  class="d-flex justify-content-end"
                >
                  <b-form-input
                    id="phone-number"
                    v-model="customerDataInfo.phoneNumber"
                    v-remove-non-numeric-chars.allNumber

                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.phoneNumber')"
                  />
                </div>
                <div v-else>
                  <span class="font-weight-bold">{{ customerDataInfo.phoneNumber }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>

          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.emailAddress')} `"
                label-for="email"
              >
                <div v-if="readonlys === false">
                  <b-form-input
                    id="email"
                    v-model="customerDataInfo.emailAddress"
                    :disabled="readonlys"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.emailAddress')"
                  />
                </div>
                <div v-else>
                  <span>{{ customerDataInfo.emailAddress || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.gender')} ${isEditActive}`"
                label-for="gender"
              >
                <div v-if="!readonlys">
                  <v-select
                    v-model="customerDataInfo.gender"
                    label="value"
                    :options="genderOptionsGender"

                    :reduce="(option) => option.value"
                    class="fixed-width-select"
                    style="max-width: 190px; background: #ffff; border-radius: 10px;"
                    :placeholder="$t('customer.gender')"
                    :clearable="false"
                  />
                </div>
                <!-- @change="validate" -->
                <div v-else>
                  <span>{{ customerDataInfo.gender || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex justify-content-between align-items-center"
                :label="`${$t('customer.Birthday')} `"
                style="width: 100%;"
                label-for="birthDay"
              >
                <ValidationProvider
                  #default="{errors }"
                  name="Birthday"
                  :rules="{ required: true, ageCategory: customerDataInfo.gender }"
                >
                  <div
                    v-if="!readonlys"
                    style=" max-width: 190px !important"
                  >
                    <flat-pickr
                      id="birthday"
                      v-model="customerDataInfo.birthDay"
                      class="form-control mb-0 bg-white"
                      :disabled="readonlys"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('customer.Birthday')"
                      :config="{
                        dateFormat: 'Y-m-d',
                        altFormat: 'd-m-Y',
                        altInput: true,
                        disableMobile: true,
                        maxDate: 'today',
                      }"
                    />
                  </div>
                  <!-- @input="validate" -->
                  <div v-else>
                    <span>{{ getDate(customerDataInfo.birthDay) || '-' }}</span>
                  </div>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false:null"
                    style="max-width: 190px"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>
            </b-row>
          </b-col>
        </b-card>
      </b-row>
    </b-col>

    <b-col>
      <b-row
        class="justify-content-center mb-1"
        style="gap:3%;"
      >
        <b-card
          class="mb-1"
          style="background: #f3f3f3; width: 360px;"
        >
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.identifyNumber')} `"
                label-for="identify-number"
              >
                <div v-if="readonlys === false">
                  <b-form-input
                    id="identify-number"
                    v-model="customerDataInfo.identifyNumber"
                    v-remove-non-numeric-chars.allNumber

                    :disabled="readonlys"

                    maxlength="15"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.identifyNumber')"
                  />
                </div>
                <div v-else>
                  <span>{{ customerDataInfo.identifyNumber || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>

          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.Dateofissue')}`"
                label-for="Dateofissue"
              >
                <div v-if="readonlys === false">
                  <div v-if="customerDataInfo?.identifyConfig">
                    <flat-pickr
                      id="Dateofissue"
                      v-model="customerDataInfo.identifyConfig.startDate"
                      :disabled="readonlys"
                      :placeholder="$t('customer.Dateofissue')"
                      class="form-control mb-0 bg-white"
                      :config="{
                        allowInput: true,
                        dateFormat: 'Y-m-d',
                        altFormat: 'd-m-Y',
                        altInput: true,
                        disableMobile: true,

                      }"
                    />
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </div>

                <div v-else>
                  <span>{{ getDate(customerDataInfo?.identifyConfig?.startDate) || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.expirationDate')}`"
                label-for="expirationDate"
              >
                <div v-if="readonlys === false">
                  <div v-if="customerDataInfo?.identifyConfig">
                    <flat-pickr
                      id="expirationDate"
                      v-model="customerDataInfo.identifyConfig.endDate"
                      :disabled="readonlys"
                      style="width: 100%;"
                      :placeholder="$t('customer.expirationDate')"
                      class="form-control mb-0 bg-white"
                      :config="{
                        allowInput: true,
                        dateFormat: 'Y-m-d',
                        altFormat: 'd-m-Y',
                        altInput: true,
                        disableMobile: true,
                      }"
                    />
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </div>
                <div v-else>
                  <span>{{ getDate(customerDataInfo?.identifyConfig?.endDate) || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.Placeofissue')} `"
                label-for="identify-number"
              >
                <div v-if="readonlys === false">
                  <div v-if="customerDataInfo.identifyConfig">
                    <b-form-input
                      id="identify-number"
                      v-model="customerDataInfo.identifyConfig.placeOfIssue"
                      :disabled="readonlys"

                      maxlength="30"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('customer.Placeofissue')"
                    />
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </div>
                <div v-else>
                  <span>{{ customerDataInfo?.identifyConfig?.placeOfIssue || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>
          <div class="d-flex justify-content-end">
            <BButton
              v-b-tooltip.hover.focus.top="`Quét QR Code CCCD/Passport`"
              variant="flat-primary"
              class="rounded-circle p-50"
              @click="onClickQrScanHandle(`qr-code-scan-passport-identify`)"
            >
              <IAmIcon
                icon="qrCode"
                size="18"
                color="black"
              />
            </BButton>

            <IAmQrCodeScan
              :idProp="`qr-code-scan-passport-identify`"
              @apply="value => customerDataInfo.identifyNumber = value"
            />

            <BButton
              v-b-tooltip.hover.focus.top="`Tải Ảnh để quét QR Code CCCD/Passport`"
              variant="flat-primary"
              class="rounded-circle p-50"
              @click="onClickQrScanHandle(`qr-code-scan-by-upload-passport-identify`)"
            >
              <IAmIcon
                icon="cloudUploadOutline"
                size="18"
                color="black"
              />
            </BButton>

            <IAmQrCodeScanByUpload
              :idProp="`qr-code-scan-by-upload-passport-identify`"
              @apply="value => customerDataInfo.identifyNumber = value"
            />
          </div>
        </b-card>
        <b-card style="background: #f3f3f3; width: 360px;">
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.city')}`"
                label-for="city"
              >
                <div v-if="readonlys === false">
                  <b-form-group class="mb-0 form-group-custom " />
                  <v-select
                    id="city"
                    v-model="customerDataInfo.addressCityId"
                    :reduce="val => val.id"
                    :options="optionsIdCity"
                    label="name"
                    class="bg-white fixed-width-select"
                    style=" max-width: 190px; border-radius: 10px;"
                    :disabled="readonlys"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.city')"
                  >
                    <template #option="{ name }">
                      <span>{{ name }}</span>
                    </template>

                    <template #selected-option="{ name }">
                      <span>{{ name }}</span>
                    </template>

                    <template #no-options>
                      {{ $t('customer.noOptions') }}
                    </template>
                  </v-select>
                </div>
                <div v-else>
                  <span class="font-weight-bold">{{ customerDataInfo.addressCityId?.name || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>

          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.district')}`"
                label-for="district"
              >
                <div v-if="readonlys === false">
                  <v-select
                    id="district"
                    v-model="customerDataInfo.addressDistrictId"
                    style=" max-width: 190px; width: 100%; background: #fff; border-radius: 10px;"
                    :options="optionsIdDitric"
                    :reduce="val => val.id"
                    :disabled="readonlys"
                    class="fixed-width-select"
                    label="name"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.district')"
                  >
                    <template #option="{name}">
                      <span>{{ name }}</span>
                    </template>

                    <template #selected-option="{ name }">
                      <span>{{ name }}</span>
                    </template>

                    <template #no-options>
                      {{ $t('customer.noOptions') }}
                    </template>
                  </v-select>
                </div>
                <div v-else>
                  <span>{{ customerDataInfo.addressDistrictId?.name || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.ward')}`"
                label-for="social-accounts"
              >
                <div v-if="readonlys === false">
                  <v-select
                    id="social-accounts"
                    v-model="customerDataInfo.addressWardId"
                    :disabled="readonlys"
                    :reduce="val => val.id"
                    style="max-width: 190px; background: #ffff; border-radius: 10px;"
                    label="name"
                    class="fixed-width-select"
                    :options="optionsIdWard"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.ward')"
                  >
                    <template #option="{ name }">
                      <span>{{ name }}</span>
                    </template>

                    <template #selected-option="{ name }">
                      <span>{{ name }}</span>
                    </template>

                    <template #no-options>
                      {{ $t('customer.noOptions') }}
                    </template>
                  </v-select>
                </div>
                <div v-else>
                  <span>{{ customerDataInfo.addressWardId?.name || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>
          <b-row>
            <b-col>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.street')}`"

                label-for="street"
              >
                <div v-if="readonlys === false">
                  <b-form-input
                    id="street"
                    v-model="customerDataInfo.addressStreet"
                    style=" background: #ffff; border-radius: 5px;"
                    :disabled="readonlys"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.street')"
                  />
                </div>
                <div v-else>
                  <span>{{ customerDataInfo.addressStreet || '-' }}</span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
    </b-col>
    <b-col>
      <b-row
        class="justify-content-center mb-1"
        style="gap:3%;"
      >
        <b-card
          class="mb-1"
          style="background: #f3f3f3; width: 360px;"
        >
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.passPort')} `"
                label-for="passportNumber"
              >
                <div
                  v-if="readonlys === false"
                  class="d-flex justify-content-end"
                >
                  <b-form-input
                    id="passportNumber"
                    v-model="customerDataInfo.passportNumber"

                    :disabled="readonlys"

                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('customer.passPort')"
                  />
                </div>
                <div v-else>
                  <span>{{ customerDataInfo.passportNumber || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>

          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.Dateofissue')}`"
                label-for="Dateofissue"
              >
                <div v-if="readonlys === false">
                  <div v-if="customerDataInfo.passportConfig">
                    <flat-pickr
                      id="Dateofissue"
                      v-model="customerDataInfo.passportConfig.startDate"
                      :disabled="readonlys"
                      style="width: 100%;"
                      :placeholder="$t('customer.Dateofissue')"
                      class="form-control mb-0 bg-white"
                      :config="{
                        allowInput: true,
                        dateFormat: 'Y-m-d',
                        altFormat: 'd-m-Y',
                        altInput: true,
                        disableMobile: true,

                      }"
                    />
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </div>
                <div v-else>
                  <span>{{ customerDataInfo?.passportConfig?.startDate || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.expirationDate')}`"
                label-for="expirationDate"
              >
                <div v-if="readonlys === false">
                  <div v-if="customerDataInfo.passportConfig">
                    <flat-pickr
                      id="expirationDate"
                      v-model="customerDataInfo.passportConfig.endDate"
                      :disabled="readonlys"
                      style="width: 100%;"
                      :placeholder="$t('customer.expirationDate')"
                      class="form-control mb-0 bg-white"
                      :config="{
                        allowInput: true,
                        dateFormat: 'Y-m-d',
                        altFormat: 'd-m-Y',
                        altInput: true,
                        disableMobile: true,

                      }"
                    />
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </div>
                <div v-else>
                  <span>{{ getDate(customerDataInfo?.passportConfig?.endDate) || '-' }}</span>
                </div>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.Placeofissue')} ${isEditActive}`"
                label-for="identify-number"
              >
                <validation-provider
                  #default="validationContext"
                  name="Place of issue"
                  rules="required"
                  mode="eager"
                >
                  <div v-if="readonlys === false">
                    <div v-if="customerDataInfo.passportConfig">
                      <IAmCountrySelect
                        :id="'identify-number'"
                        v-model="customerDataInfo.passportConfig.placeOfIssue"
                        :disabled="readonlys"
                        class="bg-white fixed-width-select"
                        style=" max-width: 190px; border-radius: 10px;"

                        :state="
                          getValidationState(validationContext) === false
                            ? false
                            : null
                        "
                        maxlength="30"
                        lazy-formatter
                        :formatter="trimInput"
                        :placeholder="$t('customer.Placeofissue')"
                      />
                    </div>
                    <div v-else>
                      <span>-</span>
                    </div>
                  </div>
                  <div v-else>
                    <span>{{ customerDataInfo?.passportConfig?.placeOfIssue || '-' }}</span>
                  </div>
                  <b-form-invalid-feedback
                    style="max-width: 190px"

                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-form-group
                class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                style="width: 100%;"
                :label="`${$t('customer.nationality')} ${isEditActive}`"
                label-for="nationality"
              >
                <validation-provider
                  #default="validationContext"
                  name="nationality"
                  rules="required"
                  mode="eager"
                >
                  <div v-if="readonlys === false">
                    <div v-if="customerDataInfo.passportConfig">
                      <IAmCountrySelect
                        :id="'city'"
                        v-model="customerDataInfo.passportConfig.nationality"
                        class="bg-white fixed-width-select"
                        style=" max-width: 190px; border-radius: 10px;"
                        :disabled="readonlys"
                        :placeholder="$t('customer.nationality')"
                      >
                        <template #option="{ name }">
                          <span>{{ name }}</span>
                        </template>

                        <template #selected-option="{ name }">
                          <span>{{ name }}</span>
                        </template>

                        <template #no-options>
                          {{ $t('customer.noOptions') }}
                        </template>
                      </IAmCountrySelect>
                    </div>
                    <div v-else>
                      <span>-</span>
                    </div>
                  </div>
                  <div v-else>
                    <span>{{ customerDataInfo?.passportConfig?.nationality || '-' }}</span>
                  </div>
                  <b-form-invalid-feedback
                    style="max-width: 190px"

                    :state="
                      getValidationState(validationContext) === false
                        ? false
                        : null
                    "
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-row>
            <b-row>
              <b-col class="p-0">
                <b-form-group
                  :label="`${$t('customer.Gender')}`"
                  class="font-weight-bold d-flex flex-1 justify-content-between align-items-center"
                  style="width: 100%;"
                  label-for="gender"
                >
                  <div v-if="readonlys === false">
                    <v-select
                      v-model="customerDataInfo.gender"
                      label="label"
                      class="fixed-width-select"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      style="max-width: 190px; background: #ffff;border-radius: 10px;"
                      :placeholder="$t('customer.Gender')"
                      :options="genderOptionsGender"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      :disabled="true"
                      input-id="gender-type"
                    >
                      <template #option="{label}">
                        {{ $t(`customer.genderOptionsGender.${label}`) }}
                      </template>

                      <template #selected-option="{label}">
                        <span>
                          {{ $t(`customer.genderOptionsGender.${label}`) }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('customer.noOptions') }}
                      </template>
                    </v-select>
                  </div>
                  <div v-else>
                    <span>
                      {{
                        genderOptionsGender.find(option => option.value === customerDataInfo.gender)?.label || '-'
                      }}
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-card>
        <b-card
          class="mb-1"
          style="background: #f3f3f3 ; width: 360px;"
        >
          <!-- {{ customerDataInfo }} -->
          <div v-if="customerDataInfo.memberCards">
            <b-col>
              <b-row>
                <div
                  v-for="(item,idx) in customerDataInfo.memberCards "
                  :key="idx"
                  style="width: 100%;"
                >
                  <div
                    v-if="readonlys === false"
                    style="width: 100%; "
                    class="d-flex justify-content-end font-weight-bold mb-1 align-items-center"
                  >
                    <v-select
                      v-model="customerDataInfo.memberCards[idx]"
                      label="label"
                      style=" "
                      class="membership-airline-select"
                      :placeholder="$t('customer.memberCards.airline')"
                      :options="memberCardsOption"
                      :clearable="false"
                      :disabled="readonlys"
                      input-id="type"
                    >
                      <template #option="item">
                        <span>
                          {{ item.airline }}
                        </span>
                      </template>

                      <template #selected-option="item">
                        <span>
                          {{ item.airline }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('customer.noOptions') }}
                      </template>
                    </v-select>
                  </div>
                  <div v-else>
                    <span> {{ customerDataInfo.memberCards[idx].airline? `${customerDataInfo.memberCards[idx].airline} (${customerDataInfo.memberCards[idx].supplierCode})` : '-' }} </span>
                  </div>

                  <div
                    v-if="readonlys === false"
                    class="d-flex justify-content-end font-weight-bold align-items-center mb-1"
                    style="width: 100; "
                  >
                    <b-form-input
                      id="membership-card"
                      v-model="item.cardNumber "
                      v-remove-non-numeric-chars.allNumber
                      style=" max-width: 100%; border: none!important; border-radius: 50px !important;"
                      :disabled="readonlys"
                      maxlength="30"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('customer.memberCards.cardNumber')"
                    />
                  </div>
                  <div v-else>
                    <span>{{ item.cardNumber || '-' }}</span>
                  </div>
                </div>
              </b-row>
            </b-col>
          </div>
          <div
            v-else
            class="mb-2"
          >
            <span>{{ $t('customer.noMemberShipCard') }}!</span>
          </div>
          <div class="d-flex justify-content-end gap-1">
            <!-- <b-button
              variant="danger"
              size="sm"
              @click="removeMemberCard(idx)"
            >
              {{ $t('customer.deleteMemberCard') }}
            </b-button> -->
            <b-button
              style="background: none !important; color: rgba(239, 173, 2, 1) !important; border: none !important;box-shadow: none !important;"
              size="sm"

              @click="addMemberCard"
            >
              <feather-icon icon="PlusCircleIcon" />
              {{ $t('customer.addNewMemberCard') }}
            </b-button>
          </div>
        </b-card>
      </b-row>
    </b-col>
    <b-col>
      <b-row class="d-flex justify-content-center">
        <b-card
          class="mb-1"
          style="background: #f3f3f3 ; width: 100%;"
        >
          <div v-if="readonlys === false">
            <b-form-textarea
              id="note"
              v-model="customerDataInfo.note"
              lazy-formatter
              :disabled="readonlys"
              style="width: 100%;"
              :formatter="trimInput"
              :placeholder="$t('customer.note')"
              rows="4"
              no-auto-shrink
            />
          </div>
          <div v-else>
            <span>{{ customerDataInfo.note || '-' }}</span>
          </div>
        </b-card>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import {
  BFormInvalidFeedback, BCard, BCol, BRow, BFormGroup, BFormInput, BFormTextarea, BButton, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ValidationProvider, ValidationObserver, validate, extend,
} from 'vee-validate'
import {
  ref, computed, watch, nextTick, onMounted,
} from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { min } from 'moment'

import IAmCountrySelect from '@/components/IAmCountrySelect.vue'
import formValidation from '@/@core/comp-functions/forms/form-validation'
import { apiCustomer } from '@/api'
import IAmQrCodeScan from '@/components/IAmQrCodeScan.vue'
import IAmQrCodeScanByUpload from '@/components/IAmQrCodeScanByUpload.vue'

import { trimInput } from '@core/comp-functions/forms/formatter-input'
import { getDate, formatCurrency } from '@core/utils/filter'

import ModalEditContactSocialNetwork from './ModalEditContactSocialNetWork.vue'

extend('ageCategory', {
  validate(value, { gender }) {
    if (!value || !gender) return false

    const today = new Date()
    const birthDate = new Date(value)
    const age = today.getFullYear() - birthDate.getFullYear()
    const monthDiff = today.getMonth() - birthDate.getMonth()

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1
    }

    if (age < 12 && gender === 'MSTR') return true
    if (age < 12 && gender === 'MISS') return true
    if (age >= 12 && (gender === 'MR' || gender === 'MRS')) return true

    return false
  },
  params: ['gender'],
  message: 'Invalid age category for the selected gender.',
})

export default {
  components: {
    BFormInput,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    IAmQrCodeScanByUpload,
    IAmQrCodeScan,
    BLink,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BFormTextarea,
    vSelect,
    flatPickr,
    ModalEditContactSocialNetwork,
    IAmCountrySelect,
  },
  props: {
    dataContactEdit: {
      type: Object,
      required: true,
    },
    readonlys: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: 'edit',
    },
  },
  setup(props, { root, emit }) {
    const optionsIdCity = ref([])
    const optionsIdDitric = ref([])
    const isDetailSocial = ref(true)
    const optionsIdWard = ref([])
    const customerData = ref({
      firstName: '',
      lastName: '',
      birthDay: '',
      gender: 'MR',
      addressCityId: null,
      note: null,
      addressDistrictId: null,
      emailAddress: null,
      addressWardId: null,
      addressStreet: null,
      createdAt: null,
      // type: 'ADULT',
      identifyNumber: '',
      passportConfig: null,
      passportNumber: '',
      socialAccounts: [{
        type: null,
        url: null,
      }],
      memberCards: [
        {
          cardNumber: '',
          airline: '',
          supplierCode: '',
        },
      ],
      identifyConfig: {
        startDate: null,
        endDate: null,
        placeOfIssue: '',
        nationality: '',

      },
    })
    const customerDataInfo = ref({})
    watch(
      () => props.mode,
      newValue => {
        if (newValue === 'edit') {
          customerDataInfo.value = {
            ...props.dataContactEdit,
            passportConfig: {
              startDate: props.dataContactEdit?.passportConfig?.startDate || null,
              endDate: props.dataContactEdit?.passportConfig?.endDate || null,
              placeOfIssue: props.dataContactEdit?.passportConfig?.placeOfIssue || null,
              nationality: props.dataContactEdit?.passportConfig?.nationality || null,
            },
            identifyConfig: {
              startDate: props.dataCustomerEdit?.identifyConfig?.startDate || null,
              endDate: props.dataCustomerEdit?.identifyConfig?.endDate || null,
              placeOfIssue: props.dataCustomerEdit?.identifyConfig?.placeOfIssue || '',
              nationality: props.dataCustomerEdit?.identifyConfig?.nationality || '',
            },
            addressCityId: props.dataContactEdit?.addressCity || null,
            addressDistrictId: props.dataContactEdit?.addressDistrict || null,
            addressWardId: props.dataContactEdit?.addressWard || null,
            memberCards: props.dataContactEdit?.memberCards?.map(card => ({
              cardNumber: card.cardNumber || '',
              airline: card.airline || null,
              supplierCode: card.supplierCode || null,
            })),

          }
        } else {
          customerDataInfo.value = {
            firstName: '',
            lastName: '',
            birthDay: '',
            gender: 'MR',
            addressCityId: null,
            addressDistrictId: null,
            createdAt: null,
            phoneNumber: null,
            note: null,
            emailAddress: null,
            addressWardId: null,
            addressStreet: null,
            // type: 'ADULT',
            socialAccounts: [{
              type: null,
              url: null,
            }],
            memberCards: [
              {
                cardNumber: '',
                airline: 'Vietnam Airlines',
                supplierCode: 'VN1A',
              },
            ],
            identifyNumber: '',
            passportNumber: '',
            passportConfig: {
              startDate: null,
              endDate: null,
              placeOfIssue: '',
              nationality: '',
            },
            identifyConfig: {
              startDate: null,
              endDate: null,
              placeOfIssue: '',
              nationality: '',
            },
          }
        }
        emit('add-contact-data-modal', customerDataInfo.value)
      },
      { immediate: true },
    )

    const memberCardsOption = ref([
      { airline: 'Vietnam Airlines', supplierCode: 'VN1A' },
      { airline: 'Bamboo Airways', supplierCode: 'QH' },

    ])

    const readonly = ref(true)
    const { refFormObserver, getValidationState, resetForm } = formValidation(customerData)
    const genderOptionsGender = ref([
      { label: 'Male', value: 'MR' },
      { label: 'Female', value: 'MRS' },
      { label: 'Male', value: 'MSTR' },
      { label: 'Female', value: 'MISS' },
    ])
    const ageCategory = computed(() => {
      const { gender, birthDay } = customerDataInfo.value
      if (!gender || !birthDay) return ''
      const today = new Date()
      const birthDate = new Date(birthDay)
      const age = today.getFullYear() - birthDate.getFullYear()
      const monthDiff = today.getMonth() - birthDate.getMonth()
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        return age - 1
      }

      if (age < 12) return gender === 'MSTR' ? 'Male' : 'Female'
      return gender === 'MR' ? 'Male' : 'Female'
    })

    const flatpickrConfig = {
      dateFormat: 'Y-m-d',
    }
    const getDate = value => (value ? new Date(value).toLocaleDateString() : '-')

    // const typeOptons = ref([
    //   { label: 'ADULT', value: 'ADULT' },
    //   { label: 'CHILD', value: 'CHILD' },
    //   { label: 'INFANT', value: 'INFANT' },
    // ])
    const modalId = 'modal-customer-edit-contact-social-network'
    const bvModal = root.$bvModal
    const editSocialNetwork = async data => {
      if (!data) return

      await new Promise(resolve => resolve())

      bvModal.show(modalId)
    }
    const onClickQrScanHandle = id => {
      bvModal.show(id)
    }
    const handleDetailSocialAccountsUpdate = updatedAccounts => {
      customerDataInfo.value.socialAccounts = updatedAccounts
    }
    const addMemberCard = () => {
      if (!customerDataInfo.value.memberCards) {
        customerDataInfo.value.memberCards = []
      }

      customerDataInfo.value.memberCards.push({
        airline: '',
        cardNumber: '',
      })
    }

    // Hàm xóa đối tượng
    const removeMemberCard = index => {
      if (!customerDataInfo.value.memberCards) {
        customerDataInfo.value.memberCards = []
      }
      customerDataInfo.value.memberCards.splice(index, 1)
    }
    const isEditActive = computed(() => {
      const val = props.readonlys === true ? '' : '(*)'
      return val
    })

    const fetchCountries = async () => {
      try {
        const res = await apiCustomer.getAdministrativeUnits({ parentId: 1, page: 1, size: 63 })
        optionsIdCity.value = res.data.items
      } catch (e) {
        console.error('Error fetch countries', e)
      }
    }
    if (customerDataInfo.value.addressCityId) {
      apiCustomer.getAdministrativeUnits({ parentId: customerDataInfo.value.addressCityId.id })
        .then(res => {
          optionsIdCity.value = res.data.items
        })
        .catch(err => {
          console.error(err)
        })
    }
    else if (customerDataInfo.value.addressDistrictId) {
      apiCustomer.getAdministrativeUnits({ parentId: customerDataInfo.value.addressDistrictId.id })
        .then(res => {
          optionsIdDitric.value = res.data.items
        })
        .catch(err => {
          console.error(err)
        })
    }
    else if (customerDataInfo.value.addressDistrictId) {
      apiCustomer.getAdministrativeUnits({ parentId: customerDataInfo.value.addressDistrictId.id })
        .then(res => {
          optionsIdWard.value = res.data.items
        })
        .catch(err => {
          console.error(err)
        })
    }
    watch(
      () => customerDataInfo.value.addressCityId,
      newValue => {
        if (!newValue) {
          optionsIdDitric.value = []
          optionsIdWard.value = []
          customerDataInfo.value.addressDistrictId = null
          customerDataInfo.value.addressWardId = null
        } else {
          const fetchDitric = async () => {
            try {
              const res = await apiCustomer.getAdministrativeUnits({ parentId: customerDataInfo.value.addressCityId, page: 1, size: 63 })
              optionsIdDitric.value = res.data.items
              customerDataInfo.value.addressDistrictId = null
              customerDataInfo.value.addressWardId = null
            } catch (e) {
              console.error('Error fetch countries', e)
            }
          }
          fetchDitric()
        }
      },
    )
    watch(
      () => customerDataInfo.value.addressDistrictId,
      newValue => {
        if (!newValue) {
          optionsIdWard.value = []
          customerDataInfo.value.addressWardId = null
        } else {
          const fetchWard = async () => {
            try {
              const res = await apiCustomer.getAdministrativeUnits({ parentId: customerDataInfo.value.addressDistrictId, page: 1, size: 63 })
              optionsIdWard.value = res.data.items
              customerDataInfo.value.addressWardId = null
            } catch (e) {
              console.error('Error fetch countries', e)
            }
          }
          fetchWard()
        }
      },
    )

    onMounted(() => {
      fetchCountries()
    })
    return {
      customerData,

      ageCategory,
      isEditActive,
      flatpickrConfig,
      removeMemberCard,
      addMemberCard,
      optionsIdCity,
      optionsIdDitric,
      optionsIdWard,
      fetchCountries,
      editSocialNetwork,
      readonly,
      getValidationState,
      getDate,
      formatCurrency,
      onClickQrScanHandle,
      trimInput,
      genderOptionsGender,
      // typeOptons,
      memberCardsOption,
      isDetailSocial,
      flatPickr,
      customerDataInfo,
      handleDetailSocialAccountsUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.fixed-width-select{
  width: 100%;
  min-width: 190px;
}
.membership-airline-select ::v-deep {
  width: 100%;
  min-width: 190px;
  background: #ffff;
   border-radius: 50px;
  .vs__dropdown-toggle {
    border: none !important;

  }

}

.fixed-feedback {
  min-height: 20px;
  line-height: 20px;
}

</style>
