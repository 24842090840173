<template>
  <div>
    <b-row
      class="justify-content-between align-items-end m-0"
      style="width: 100%;"
    >
      <b-col
        sm="12"
        md="3"
        lg="2"
        class="justify-content-center mb-1 pl-0"
      >
        <b-form-group class="mb-0 form-group-custom  ">
          <v-select
            id="nested-city"
            v-model="selectedIdCity"
            :reduce="val => val.id"
            :placeholder="$t('customer.placeholderCity')"
            :options="optionsIdCity"
            label="name"
          >
            <template #option="{name}">
              <span> {{ name }}</span>
            </template>

            <template #selected-option="{name}">
              <span> {{ name }}</span>
            </template>

            <template #no-options>
              {{ $t('customer.noOptions') }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        sm="12"
        md="3"
        lg="2"

        class="justify-content-center mb-1 "
      >
        <b-form-group class="mb-0 form-group-custom ">
          <v-select
            id="nested-state"
            v-model="selectedIdDistrict"
            :reduce="val => val.id"
            label="name"
            :options="optionsIdDitric"
            :placeholder="$t('customer.placeholderDistrict')"
          >
            <template #option="{name}">
              <span>
                {{ name }}
              </span>
            </template>

            <template #selected-option="{name}">
              <span>
                {{ name }}
              </span>
            </template>

            <template #no-options>
              {{ $t('customer.noOptions') }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        sm="12"
        md="3"
        lg="4"
        class="justify-content-center mb-1 "
      >
        <b-form-group class="mb-0 flex-grow-1">
          <b-input-group>
            <b-form-input
              id="nested-country"
              v-model="searchTextFilter"
              type="search"
              class="search-input"
              :placeholder="$t('customer.placeholderSearch')"
              :value="searchTextFilter"
              :reduce="val => val.value"
              maxlength="255"
              debounce="500"
              trim
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="12"
        md="3"
        lg="2"
        class="justify-content-center mb-1 "
      >
        <b-form-group
          label-class="h5"
          class="mb-0"
        >
          <!-- <feather-icon icon="CalendarIcon" /> -->
          <flat-pickr
            v-model="dateRange"
            class="form-control mb-0"
            :placeholder="$t('customer.balanceStartDate')"
            :config="{
              mode: 'range',
              dateFormat: 'Y-m-d',
              altFormat: 'd-m-Y',
              class: 'form-control-md',
              altInput: true,
              locale: i18n.locale === 'vi' ? Vietnamese : null,
              disableMobile: true,
            }"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="12"
        md="3"
        lg="2"
        class=" justify-content-center  mb-1 pr-0"
      >
        <b-button
          variant="warning"
          class="btn-add-custom"

          @click="toggleAddCustomer"
        >
          <feather-icon
            icon="UserPlusIcon"
            size="18"
            style="color: white !important;font-weight:600 ; align-items: center; margin-right: 10px;"
          />
          <i class="pi pi-plus-circle" />{{ $t('customer.addCustomer') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BFormInput, BInputGroup, BFormSelect, BFormGroup, BCol, BRow,
} from 'bootstrap-vue'
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import i18n from '@/libs/i18n'
import { apiCustomer } from '@/api'

export default {
  components: {
    BInputGroup, BFormInput, BButton, BFormSelect, BFormGroup, vSelect, flatPickr, BCol, BRow,
  },
  props: {
    updatedCustomerInfo: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const today = new Date().toISOString().split('T')[0]
    const dateRange = ref([])
    const searchTextFilter = ref('')
    const loading = ref(false)
    const optionsIdCity = ref([])
    const optionsIdDitric = ref([])
    const selectedIdDistrict = ref()
    const selectedIdCity = ref()

    const fetchCountries = async () => {
      try {
        const res = await apiCustomer.getAdministrativeUnits({ parentId: 1, page: 1, size: 63 })
        optionsIdCity.value = res.data.items
      } catch (e) {
        console.error('Error fetch countries', e)
      }
    }
    const toggleAddCustomer = toggle => {
      emit('toggle-add-customer', toggle)
    }

    watch(
      () => selectedIdCity.value,
      async newValue => {
        selectedIdDistrict.value = null
        optionsIdDitric.value = []
        if (!newValue) {
          emit('filter-city', newValue)
          return
        }

        loading.value = true
        try {
          const res = await apiCustomer.getAdministrativeUnits({
            parentId: newValue,
            page: 1,
            size: 63,
          })
          optionsIdDitric.value = res.data.items
        } catch (e) {
          console.error('Error fetch districts', e)
        } finally {
          loading.value = false
        }
        emit('filter-city', newValue)
      },
    )
    watch(selectedIdDistrict, newValue => {
      emit('filter-distric', newValue)
    })
    watch(dateRange, newValue => {
      emit('update-dates', newValue)
    })

    onMounted(() => {
      fetchCountries()
    })

    watch(searchTextFilter, newValue => {
      emit('filter-customer', newValue)
    })

    watch(dateRange, newValue => {
      if (!newValue) return

      const sepSign = i18n.locale === 'vi' ? ' đến ' : ' to '
      const dateRange = newValue.split(sepSign)

      if (dateRange.length > 1) {
        emit('startDateFilter', dateRange[0])
        emit('endDateFilter', dateRange[1])
      } else if (dateRange.length === 1) {
        emit('startDateFilter', dateRange[0])
        emit('endDateFilter', dateRange[0])
      }
    })

    return {
      searchTextFilter,
      selectedIdDistrict,
      optionsIdDitric,
      selectedIdCity,
      optionsIdCity,
      fetchCountries,
      toggleAddCustomer,
      flatPickr,
      today,
      dateRange,
      Vietnamese,
      i18n,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
